import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { Typography, message, Input } from 'antd'
import Title from 'antd/lib/typography/Title'
import useTable from '../../Utils/useTable'
import { lostAndFoundListFetch, setLostOrFound } from '../../Reducers/lostAndFound'
import LostAndFoundTable from './LostAndFoundTable'
import LostButton from './GroupEditing/LostButton'
import FoundButton from './GroupEditing/GroupEnablingButtonGroup'
import GroupEnablingButtonGroup from './GroupEditing/GroupEnablingButtonGroup'


const { Search } = Input

function LostAndFound() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.lostAndFound.list, lostAndFoundListFetch);

  const fieldsChange = [
    "id",
    "status",
]
  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  const dispatch = useDispatch()

  const updateFound = useCallback((params) => {
    dispatch(setLostOrFound(params)).then((e) => {
        try {
            message.success('Proxies completed update.');
            fetch()
        }catch (e) {
            message.error(`Can't update Proxies: ${e}`);
            fetch()
        }
    })
}, [dispatch, fetch]);

  return (
    <>
      <Typography.Title>Lost and found</Typography.Title>
      <div className="buttonsGroup">
        {/* <LostButton selected={selectedRow} afterUpdate={fetch} /> */}
        <GroupEnablingButtonGroup selected={selectedRow} afterUpdate={fetch} />
        {
          !!selectedRow.length
          && <span style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginLeft: "15px", marginRight: "4px"}}><b>
            for {selectedRow.length} rows
          </b></span>
        }
      </div>
      {/* <Search defaultValue={query} placeholder="Input search text" className="mb" size="large" onSearch={onSearch} /> */}
      <LostAndFoundTable
        list={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
        fieldsChange={fieldsChange}
        handleSave={updateFound}
      />
    </>
  );
}

export default LostAndFound;
