import React from 'react';
import { serverDateToDate, serverDateToDateWithTime } from '../../../Utils/dates';

function PrintDate({ className, date, withTime }) {
  const prepareFunc = (
    withTime ? serverDateToDateWithTime : serverDateToDate
  );

  return (
  <span className={className}>
    {
    date ?
    `${prepareFunc(date)}`
    :
    ''
    }
  </span>
  );
}

export default PrintDate;
