import ApiService from './ApiService';

const LostAndFoundService = {
  getLostAndFoundList({ orderPath, ...params }) {
    const updatedParams = { ...params };
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '')
    }
    return ApiService.get('/depot_gifts/lost_and_found', updatedParams)
  },

  editLostOrFound(params) {
    return ApiService.post('/depot_gift/set_status', params)
  },

  groupEditLostAndFound(params) {
    return ApiService.put('/depot_gift/set_status/group', params)
  },
};

export default LostAndFoundService
