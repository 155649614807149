import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { Typography, message, Input, Affix } from 'antd'
import useTable from '../../Utils/useTable'
import { RedeemJobsListFetch } from '../../Reducers/redeemJobs'
import JobFilters from '../../Components/JobFilters';
import CancelJobButton from '../../Components/CancelJobButton'
import JobsTableWrapper from '../../Components/JobsTableWrapper'

const { Search } = Input

function RedeemJobs() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.redeemJobs.list, RedeemJobsListFetch);


  return (
    <>
      <Typography.Title>Redeem Jobs</Typography.Title>
      <JobFilters filters={filters} onFilter={onFilter} className="mb"/>
      <JobsTableWrapper
        jobTableType='redeem_jobs_table'
        jobsList={list}
        onChange={onChange}
        pagination={pagination}
        isLoading={isLoading}
        fetch={fetch}
        onFilter={onFilter}
        filters={filters}
      />
    </>
  );
}

export default RedeemJobs;
