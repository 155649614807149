const extractIDs = (array) => {
  var result = [];
  for (var i = 0; i < array.length; i++) {
    var value = array[i].split('_')[0];
    result.push(parseInt(value));
  }
  return result;
}

export default extractIDs
