export function downloadFile({ data, fileName, type }) {
  const a = document.createElement('a');
  console.log('FILETYPE', data, fileName, type)
  if (type === 'blob') {
    a.href = window.URL.createObjectURL(data);
  } else if (type === 'csv') {
    const csvContent = `data:text/csv;charset=utf-8,${data}`;
    a.href = encodeURI(csvContent);
  } else {
    throw new Error('Invalid file type');
  }

  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
  return {};
}
