import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Input, message, InputNumber, Select} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import b_ from 'b_';
import { virtualMachineCreate } from '../../../Reducers/virtualMachines';

const b = b_.lock('NewButton');

function CreateVirtualMachine({ className, afterUpdate, groups }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.accounts.create.isLoading, shallowEqual);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const [proxy, setProxy] = useState("");


  const handleSuccess = useCallback((data) => {
    Object.keys(data).map(e => !data[e] ? data[e] = "" : null)
    dispatch(virtualMachineCreate(data))
    .then(() => {
      close();
      afterUpdate();
      message.success('Virtual machine has been added!');
    })
    .catch((error) => {
      message.error(`Failed to create virtual machine: ${error}`)
    });
  }, [close, afterUpdate, dispatch]);

  const error = useSelector(state => state.proxies.createProxies.error, shallowEqual);

  return (
    <>
      <Button
          type="primary"
          size="medium"
          className={className}
          icon={<PlusOutlined />}
          onClick={open}
      >
        Create
      </Button>
      <Drawer visible={isOpen} title="Create VM" width={600} closable placement="right" onClose={close}>
        {/* {error && <ErrorBlock message={error.message} />} */}
        {error && message.error('Fail')}
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
        <Form.Item
        rules={[
          { required: true, message: 'Please select group.' },
        ]}
              label="Group:"
              name="groupName"
          >
              <Select>
                  {groups && groups.map(state => <Select.Option
                      key={state}
                      value={state}>{state}</Select.Option>
                  )}
              </Select>
          </Form.Item>

          <Form.Item
          rules={[
            { required: true, message: 'Please input driver.' },
          ]}
            label="Driver Name:"
            name="name"
          >
            <Input type="text" />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: 'Please input host.' },
            ]}
            label="Host:"
            name="host"
          >
            <Input type="text" />
          </Form.Item>

          <Form.Item
            rules={[
                { required: true, message: 'Please input port.' }
            ]}
            label="Port:"
            name="driverPort"
            normalize={v => +v}
          >
            <Input type="number" />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: 'Please input host.' },
            ]}
            label="Type:"
            name="vmType"
          >
              <Select onChange={(e) => setProxy(e)}>
                  {["vpn", "socks5", "smart", "oxylabs"].map(state => <Select.Option
                      key={state}
                      value={state}>{state}</Select.Option>
                  )}
              </Select>
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: 'Please input host.' },
            ]}
            label="Socks5 Host:"
            name="socks5Host"
          >
              <Input type="text" />
          </Form.Item>

          <Form.Item
            rules={[
              { required: true, message: 'Please input port.' },
            ]}
            label="Socks5 Port:"
            name="socks5Port"
            normalize={v => +v}
          >
              <Input type="number" />
          </Form.Item>
          
          
          <Form.Item
          rules={[
            { required: true, message: 'Please select status.' },
          ]}
            label="Status:"
            name="status"
          >
              <Select>
                  {["Enabled", "Disabled", "Blocked", "Test"].map(state => <Select.Option
                      key={state}
                      value={state}>{state}</Select.Option>
                  )}
              </Select>
          </Form.Item>
          
          <Form.Item
          rules={[
            { required: true, message: 'Please input engine ID.' },
          ]}
            label="Engine ID:"
            name="engineId"
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item>
            <Button
                type="primary"
                icon={isLoading && <LoadingOutlined />}
                htmlType="submit"
                >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreateVirtualMachine

