import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { fetchAccount, fetchJobsAccount, fetchOrders } from '../../Reducers/account';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import './styles.scss';
import { fetchAuxAccount } from '../../Reducers/accounts';
import useTable from '../../Utils/useTable';
import TransactionsTable from './TranscationsTable';

const b = b_.lock('AccountPage');

function AccountOrderHistory() {
  // const { payload, isLoading } = useSelector(state => state.account.list, shallowEqual)

  // console.log('data', payload)

  const dispatch = useDispatch();
  const params = useParams();

  console.log('params', params)

  const fetchOrdersAcc = useCallback((data) => fetchOrders(params.id ), []);
  const { fetch, ...props } = useTable(
    state => state.account.orders,
    fetchOrdersAcc,
  );

  console.log('fetchOrders', props)

  const [selectedRow, setSelectedRow] = useState([]);

  const ordersList = props.list && props.list

  return <div>
    <Title className="mb">Account Transactions - {params.id}</Title>
    <TransactionsTable
        list={ordersList}
        // tableOnChange={(a, b, c) => onChange(a, b, c)}
        // pagination={pagination}
        // loading={isLoading}
        afterUpdate={fetch}
        // onFilter={onFilter}
        // filters={filters}
        // selected={selectedRow}
        // onSelectChange={onSelectChange}
        // handleSave={updateVirtualMachine}
        // fieldsChange={fieldsChange}
      />
  </div>;
}

export default AccountOrderHistory;
