import { Button, Tooltip, message } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { findAndSetProxy } from '../../../Reducers/accounts';

const SetProxyButton = ({ record, afterUpdate, title, icon }) => {

  const dispatch = useDispatch();

  const { id } = record;

  const setProxyForUser = useCallback(() => {
    dispatch(findAndSetProxy(id)).then(() => {
        message.success('Found proxy for worker!');
        afterUpdate();
    }).catch(error => {
        console.log(error);
        message.error('Can\'t set worker proxy');
    })
}, [dispatch, afterUpdate, id]);
  


  return (
    <>
      <Tooltip placement="topLeft" title={title}>
        <Button
          icon={icon}
          onClick={setProxyForUser}
        />
      </Tooltip>
    </>
  )
}

export default SetProxyButton
