import { Typography, Modal, Button } from 'antd'
import {frc} from 'front-end-common';

const { Paragraph } = Typography
const useBoolean = frc.hooks.useBoolean;


function ExpandModal({ title, children }) {
  const { setTrue: open, value: isOpen, setFalse: close } = useBoolean(false);

  return <div>
    <Button
      type="primary"
      onClick={open}
      style={{width: '100%'}}
    >
      {title}
    </Button>
    <Modal visible={isOpen} destroyOnClose closable onCancel={close} footer={null} width={"90vw"} height={"90vh"} centered  >
      {children}
    </Modal>
  </div>;
}

export default ExpandModal;


