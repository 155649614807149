import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Input, message, InputNumber} from 'antd';
import { EditFilled } from '@ant-design/icons';
import b_ from 'b_';
import splitArray from '../../../Utils/splitArray';
import { groupChangeAlgoStat } from '../../../Reducers/algoStatistics';
// import { changeRatios } from '../../../Reducers/resell';

const ExpCountButton = ({
                     afterUpdate,
                     selected,
                     payload
                   }) => {

  const b = b_.lock('ExpCountButton');

  const dispatch = useDispatch();

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const handleSuccess = useCallback((data) => {
    console.log('data', data)
    console.log('selected', selected)

    if (selected.length) {
      const newArr = selected.map((el) => el.split(','))
      const objects = []
      for (let i = 0; i < newArr.length; i++) {
        const obj = { itemId: newArr[i][0] };
        objects.push(obj);
      }
      const preparedArr = splitArray(newArr)
      console.log('preparedArr', objects)

      const handleData = payload.map((item) => {
        return {...item, ...data}
      })
      console.log('handleData', handleData)

      dispatch(groupChangeAlgoStat(handleData))
      .then(() => {
        message.success('Stats have been changed!');
        afterUpdate();
        close()
      }).catch((error) => {
        // console.log(error);
        message.error('Can\'t update stats.');
      });
    }
  }, [dispatch, afterUpdate, selected]);
  
  return (
    <>
      <Drawer visible={isOpen} title={`Edit value for ${selected.length} rows`} width={300} closable placement="right" onClose={close}>
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
          <Form.Item
            label="New value"
            name="count"  
            rules={[
              {
                required: true,
                message: 'please enter new value',
              },
          ]}>
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Button
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <Button
        type="primary"
        disabled={!selected.length}
        onClick={open}
      >
        Exp count
      </Button>
    </>
  );
};

export default React.memo(ExpCountButton);
