import React, {useState, useCallback, useEffect, useMemo} from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Tooltip, Tag, message, Table, InputNumber } from 'antd'
import b_ from 'b_'
import ImageViews from '../../Components/ImageViews'
import LinksViews from '../../Components/LinksViews'
import ExpandViews from '../../Components/ExpandViews'
import useStorageState from '../../Utils/useStorageState'
import TableSettings from '../../Utils/TableSettings'
import PrintDate from '../../Components/Prints/PrintDate'
import { getStringValue } from '../../Utils/subscriptionMask'

const b = b_.lock('AlgoStatisticsTable');

function AlgoStatisticsTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      onFilter,
                      filters,
                      selected,
                      onSelectChange,
                    }) {

                      console.log('list', list)
  const GetColumns = useCallback(() => {

    return [
      {
        title: 'Item',
        dataIndex: 'itemId',
        key: 'item',
        width: 300,
        sorter: (a, b) => a.itemId.localeCompare(b.itemId),
        // render: ({ itemId }) => <p>{itemId}</p>
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        width: 90,
        sorter: (a, b) => a.currency.localeCompare(b.currency),
        // filters: [
        //   {
        //     text: 'USD',
        //     value: 'USD',
        //   },
        //   {
        //     text: 'EUR',
        //     value: 'EUR',
        //   },
        // ],
        // filteredValue: filters['currency'] || null,

      },
      {
        title: 'Subscr',
        dataIndex: 'subscr',
        key: 'subscr',
        width: 80,
        sorter: (a, b) => a.subscr - b.subscr,
        render:(_, rec) => getStringValue(rec.subscr)
              
      },
      {
        title: 'On sale',
        dataIndex: 'purchItemId.onSale',
        key: 'purchItemId.onSale',
        width: 120,
        sorter: (a, b) => a - b,
        // filters: [
        //   {
        //     text: 'True',
        //     value: 'true',
        //   },
        //   {
        //     text: 'False',
        //     value: 'false',
        //   },
        // ],
        // filteredValue: filters['on_sale'] || null,
        render: (_, rec) => <p>{rec?.purchItemId?.onSale?.toString()}</p>
      },
      {
        title: '7 day count',
        dataIndex: 'count7',
        key: 'count7',
        width: 90,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.count7) {
            return record.count7;
          } else {
            return 0;
          }
        },
      },
      {
        title: '7 day sum',
        dataIndex: 'sum7',
        key: 'sum7',
        width: 80,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.sum7) {
            return record.sum7;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Today count',
        dataIndex: 'count0',
        key: 'count0',
        width: 90,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.count0) {
            return record.count0;
          } else if (record.count1) {
            return record.count1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Today sum',
        dataIndex: 'sum0',
        key: 'sum0',
        width: 80,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.sum0) {
            return record.sum0;
          } else if (record.sum1) {
            return record.sum1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        width: 80,
        sorter: (a, b) => a.price - b.price,
      },
      {
        title: 'Price time',
        dataIndex: 'time',
        key: 'time',
        width: 80,
        sorter: (a, b) => a - b,
        render: (_, record) => <PrintDate withTime date={record.time} />,
      },
      {
        title: 'Accs can buy',
        dataIndex: 'accsCanBuy',
        key: 'accs_can_buy',
        width: 100,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Accs no money',
        dataIndex: 'accsNoMoney',
        key: 'accs_no_money',
        width: 100,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Games can buy',
        dataIndex: 'gamesCanBuy',
        key: 'games_can_buy',
        width: 100,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Max games can buy',
        dataIndex: 'maxGamesCanBuy',
        key: 'max_games_can_buy',
        width: 120,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Expected',
        dataIndex: 'expectedPurches',
        key: 'expectedPurches',
        width: 100,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          return {
            props: {
              style: { background: record?.expectedPurches > 0 ? "#e4ffcf" : "" }
            },
            children:
              // record?.expectedPurches > 0 ? <b>{record?.expectedPurches}</b> : <p>{record?.gamesPerDay}</p>
              record?.expectedPurches
          }
        }
      },
      {
        title: 'Shortage ratio',
        dataIndex: 'buyShortageRatio',
        key: 'shortage_ratio',
        width: 100,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Max shortage ratio',
        dataIndex: 'maxBuyShortageRatio',
        key: 'max_shortage_ratio',
        width: 130,
        sorter: (a, b) => a - b,
      },
    ]; 
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('algo_stat_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
});

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < columns.length,
        checkAll: checkedList.length === columns.length,
    });
}, [Checkboxs]);

useEffect(() => {
  let allCheckList = []
  if (list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
          allCheckList.push(e[Object.keys(e)[1]])
          setCheckbox(allCheckList)
      })
  }
}, [list, AllCheckbox, Checkboxs.length, GetColumns])

useEffect(() => {
  if (AllCheckbox.checkAll) {
      setAllCheckbox({
          checkedList: GetColumns().map(e => e.title),
          indeterminate: false,
          checkAll: true,
      })
  }
}, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
        checkedList: (e.target.checked && columns.map(e => e.title)) || [],
        indeterminate: false,
        checkAll: e.target.checked,
    });
}, [Checkboxs]);

useEffect(() => {
  setColumns(GetColumns())
}, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const loader = loading

  const dataList = list && list.list

  
  return <Table
            rowKey={(record) => `${record.itemId}_${record.currency}`}
            className={b()}
            bordered
            dataSource={list?.list}
            scroll={{ x: 'max-content' }}
            size="middle"
            onChange={(a, b, c) => tableOnChange(a, b, c)}
            onFilter={onFilter}
            pagination={pagination}
            loading={loader}
            columns={columnsFilter}
            title={() => <TableSettings
              onChangeCheckAll={onChangeCheckAll}
              allChecks={AllCheckbox}
              Checkboxs={AllCheckbox.checkedList}
              TableColumn={() => GetColumns()}
              setCheckbox={onChangeCheckList}
          />}
            rowClassName={() => b('lock')}
            rowSelection={{
              selectedRowKeys: selected,
              onChange: onSelectChange,
            }}
  />
}

export default AlgoStatisticsTable;
