import React, { useCallback, useEffect, useMemo, useState } from 'react';
import b_ from 'b_';
import { Button, Checkbox, Drawer, Form, Input, Select, Switch, Tag, message } from 'antd';
import useBoolean from '../../../Utils/useBoolean';
import CurrencySelector from '../../../Components/CurrencySelector';
import TextArea from 'antd/lib/input/TextArea';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ErrorBlock from '../../../Components/ErrorBlock';
import { createAccount, updateAccount, updateAccounts } from '../../../Reducers/accounts';
import PlusOutlined from '@ant-design/icons/lib/icons/PlusOutlined';
import {CheckOutlined, CloseOutlined, DeleteOutlined, EditFilled, LoadingOutlined, PoweroffOutlined} from "@ant-design/icons";
import GroupSelector from "../../../Components/GroupSelector";
import extractIDs from '../../../Utils/extractIDs';

const b = b_.lock('CreateAccount');

function UpdateAccounts ({ className, afterSuccess, selected }) {
  const dispatch = useDispatch();
  const aux = useSelector(state => state.accounts.aux.payload, shallowEqual);
  const isLoading = useSelector(state => state.accounts.create.isLoading, shallowEqual);
  const [form] = Form.useForm();

  const defaultValues = useMemo(() => ({
    balLimit: aux ? aux.limitDefault : undefined,
    purchLimit: aux ? aux.purchLimitDefault : undefined,
  }), [aux]);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const [resetFields, setResetFields] = useState([]);
  const [disabledChangeEnabledJobs, setDisabledChangeEnabledJobs] = useState(true);
  const [enabledJobs, setEnabledJobs] = useState([]);

  const changeResetFields = useCallback((field) => {
    if (resetFields.includes(field)) {
      return setResetFields(resetFields.filter(v => v !== field));
    }

    setResetFields([...resetFields, field]);
  }, [setResetFields, resetFields]);

  
  const handleToggleJob = (job) => (checked) => {
    if (checked) {
      setEnabledJobs([...enabledJobs, job]);
    } else {
      setEnabledJobs(enabledJobs.filter((enabledJob) => enabledJob !== job));
    }
  };

  const updateFormValues = () => {
    form.setFieldsValue({ enabledJobs });
  };

  useEffect(() => {
    updateFormValues();
  }, [enabledJobs]);

  const toggleEnabledJobs = () => {
    setDisabledChangeEnabledJobs(!disabledChangeEnabledJobs);
  };

  const handleSuccess = useCallback((formFields) => {
    const someExist = Object.keys(formFields).some(key => formFields[key] !== undefined);

    if (!someExist && resetFields.length === 0) {
      return message.error('Please input one of field');
    }

    const fields = {...formFields, ...resetFields.reduce((acc, cur) => ({ ...acc, [cur]: cur === "enabledJobs" ? [] : cur === "vm" ? -1 : ''}), {})};

    const selectedId = extractIDs(selected)

    const data = { accountIds: selectedId, ...fields };

    dispatch(updateAccount(data)).then(() => {
      
      setTimeout(() => {
        afterSuccess();
      }, 1000)
        message.success('Accounts has been updated!');
        form.resetFields();
      
      close();
    });
  }, [close, afterSuccess, dispatch, selected, resetFields, form]);

  const error = useSelector(state => state.accounts.create.error, shallowEqual);

  return (
    <>
      <Button
          disabled={!selected.length}
          type="primary"
          className={className}
          icon={<EditFilled />}
          onClick={open}
      >
        Update {selected.length} accounts
      </Button>
      <Drawer visible={isOpen} title="Update Accounts" width={600} closable placement="right" onClose={close}>
        {error && <ErrorBlock message={error.message} />}
        <Form form={form} layout="vertical" className={b()} onFinish={handleSuccess}>
            <Form.Item
                label="Currency:"
                name="currency"
            >
                <CurrencySelector />
            </Form.Item>
            <Form.Item
                rules={[{ type: 'number', min: 0, max: 250 , message: "Limit must be between 0 and 250"}]}
                label="Balance Limit:(250)"
                name="dayLimitEur"
                normalize={v => +v}
            >
                <Input type="number" />
            </Form.Item>
            <Form.Item label={"Phase:"} name={"phase"}>
                <Select options={[
                    { value: 'Create', label: 'Create' },
                    { value: 'Prepare', label: 'Prepare' },
                    { value: 'Redeem', label: 'Redeem' },
                    { value: 'Purchase', label: 'Purchase' },
                    { value: 'Archive', label: 'Archive' },
                    { value: 'PrepareArchive', label: 'PrepareArchive' },
                  ]}
                  />
            </Form.Item>
            <div style={{marginBottom: '16px'}}>
            <label>Enabled Jobs:</label>
            <br />
            {!disabledChangeEnabledJobs && <Form.Item name={"enabledJobs"}>
            
            <span style={{marginRight: '23px'}}>Redeem</span>
            <Switch 
            onChange={handleToggleJob("Redeem")}
            checked={enabledJobs.includes("Redeem")}
            checkedChildren={<CheckOutlined />} 
            unCheckedChildren={<CloseOutlined />}
            />

            </Form.Item>}

            {!disabledChangeEnabledJobs && <Form.Item name={"enabledJobs"}>
          
            <span style={{marginRight: '16px'}}>Purchase</span>
            <Switch 
            onChange={handleToggleJob("Purchase")}
            checked={enabledJobs.includes("Purchase")}
            checkedChildren={<CheckOutlined />} 
            unCheckedChildren={<CloseOutlined />}/>

            </Form.Item>}

            <Button onClick={toggleEnabledJobs} icon={<PoweroffOutlined />} > {disabledChangeEnabledJobs ? "Change enabled jobs" : "Don't change enabled jobs"}</Button>
            </div>
            <Form.Item label={"Restore Code:"} name={"restoreCode"}>
                <Input/>
            </Form.Item>
            <Form.Item label={"Group:"} name={"groups"}>
                  <CurrencySelector  mode={"multiple"} />
                {/* <GroupSelector mode={"multiple"}/> */}
            </Form.Item>
            <Form.Item label={"Subscription:"} name={"subscrType"}>
                <Select options={[
                    { value: 0, label: 'No' },
                    { value: 1, label: 'Pass' },
                    { value: 2, label: 'EA' },
                    { value: 3, label: 'Pass, EA' },
                    { value: 4, label: 'Pass, Full' },
                  ]}
                  />
            </Form.Item>
            <Form.Item label={<div>
                <span className="mr">VM</span>
                <span className="mr-small"><DeleteOutlined /> Delete VM</span>
                <Checkbox checked={resetFields.includes('vm')}
                          onChange={() => changeResetFields('vm')}
                />
            </div>}
                            name="vm"
                            hasFeedback={form.getFieldValue('vm')}
                            validateStatus="success"
            >
                <Input disabled={resetFields.includes('vm')} />
            </Form.Item>
            <Form.Item
                label="Comment:"
                name="comment"
            >
                <TextArea />
            </Form.Item>
          <Form.Item>
            <Button
                type="primary"
                icon={isLoading && <LoadingOutlined />}
                htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default UpdateAccounts;
