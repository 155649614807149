import React, {useState, useCallback, useEffect, useMemo} from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Tooltip, Tag, message, Table, InputNumber } from 'antd'
import b_ from 'b_'
import ImageViews from '../../Components/ImageViews'
import LinksViews from '../../Components/LinksViews'
import ExpandViews from '../../Components/ExpandViews'
import useStorageState from '../../Utils/useStorageState'
import TableSettings from '../../Utils/TableSettings'
import PrintDate from '../../Components/Prints/PrintDate'
import { Link } from 'react-router-dom'

const b = b_.lock('AccountsToCreateTable')

function AccountsToCreateTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      onFilter,
                      filters,
                      selected,
                      setSelectedRow,
                      onSelectChange,
                    }) {
  const GetColumns = useCallback(() => {

    return [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 60,
        sorter: (a, b) => a - b,
        render:id => <Link to={`/account/${id}`}>{id}</Link>,
      },
      {
        title: 'VM',
        dataIndex: 'vm',
        key: 'vm',
        width: 60,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Proxy',
        dataIndex: 'proxy',
        key: 'proxy',
        width: 60,
        sorter: (a, b) => a - b,
        render: (_, rec) => <span>{rec.details.proxyHost}:{rec.details.proxyPort}</span>
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        width: 90,
        sorter: (a, b) => a.state.localeCompare(b.state),
        filters: [
          {
            text: 'USD',
            value: 'USD',
          },
          {
            text: 'EUR',
            value: 'EUR',
          },
          {
            text: 'ARS',
            value: 'ARS',
          },
        ],
        filteredValue: filters['currency'] || null,
      },
      {
        title: 'Login',
        dataIndex: 'login',
        key: 'login',
        width: 100,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Password',
        dataIndex: 'password',
        key: 'password',
        width: 100,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        sorter: (a, b) => a - b,
        render: (_, rec) => <>{rec.details ? rec.details.name : ''}</>
      },
      {
        title: 'Surname',
        dataIndex: 'surname',
        key: 'surname',
        width: 100,
        sorter: (a, b) => a - b,
        render: (_, rec) => <>{rec.details ? rec.details.surname : ''}</>
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country',
        width: 100,
        sorter: (a, b) => a - b,
        render: (_, rec) => <>{rec.details ? rec.details.country : ''}</>
      },
      {
        title: 'Birthdate',
        dataIndex: 'birthdate',
        key: 'birthdate',
        width: 100,
        sorter: (a, b) => a - b,
        render: (_, rec) => <>{rec.details ? <PrintDate date={rec.details.birthDate} /> : ''}</> 
      },
    ]; 
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('accounts_to_create_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
});

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < columns.length,
        checkAll: checkedList.length === columns.length,
    });
}, [Checkboxs]);

useEffect(() => {
  let allCheckList = []
  if (list?.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
          allCheckList.push(e[Object.keys(e)[1]])
          setCheckbox(allCheckList)
      })
  }
}, [list, AllCheckbox, Checkboxs.length, GetColumns])

useEffect(() => {
  if (AllCheckbox.checkAll) {
      setAllCheckbox({
          checkedList: GetColumns().map(e => e.title),
          indeterminate: false,
          checkAll: true,
      })
  }
}, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
        checkedList: (e.target.checked && columns.map(e => e.title)) || [],
        indeterminate: false,
        checkAll: e.target.checked,
    });
}, [Checkboxs]);

useEffect(() => {
  setColumns(GetColumns())
}, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const loader = loading

  useEffect(() => {
    onSelectChange([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pagination])

  
  return <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  const key = `${record.id}`
                  setSelectedRow((prev) => prev.includes(key) ? prev.filter((it) => it !== key) : [...prev, key])
                },
              };
            }}
            scrollToFirstRowOnChange={true}
            rowKey={(record) => `${record.id}`}
            className={b()}
            bordered
            dataSource={list}
            scroll={{ x: 'max-content' }}
            size="middle"
            onChange={(a, b, c) => tableOnChange(a, b, c)}
            onFilter={onFilter}
            pagination={pagination}
            loading={loader}
            columns={columnsFilter}
            title={() => <TableSettings
              onChangeCheckAll={onChangeCheckAll}
              allChecks={AllCheckbox}
              Checkboxs={AllCheckbox.checkedList}
              TableColumn={() => GetColumns()}
              setCheckbox={onChangeCheckList}
          />}
            rowClassName={() => b('lock')}
            rowSelection={{
              selectedRowKeys: selected,
              onChange: onSelectChange,
            }}
  />
}

export default AccountsToCreateTable;
