import { List } from 'antd';
import React from 'react';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ListView = ({ data }) => {
  return (
    <List
                      size="small"
                      bordered
                      style={{ marginRight: "40px" }}
                      dataSource={data}
                      renderItem={(item) => <List.Item key={item}><a href={BACKEND_URL + item}>{item}</a></List.Item>}
                    />
  )
}

export default ListView
