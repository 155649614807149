import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import { Col, Button, DatePicker, Input, Row, Select } from 'antd';
import './styles.scss';
import { momentToUnix, unixToMoment } from '../../Utils/dates';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';
import CurrencySelector from '../CurrencySelector';

const { Option } = Select;

const RangePicker = DatePicker.RangePicker;

const b = b_.lock('JobFilters');

function RedeemOrderFilter({ className, filters, onFilter, withCodeFilter = true }) {
  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = useCallback((value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters((prevFilters) => ({ ...prevFilters, [field]: preparedValue }));
  }, [curFilters, setCurFilters]);

  const handleResetFilters = useCallback(() => {
    for (var prop in filters) delete filters[prop];
    setCurFilters({ curr: ['USD']});
    onFilter()
  }, [filters, onFilter])

  return (
    <>
  <Row justify={"space-between"}>
  <Col >
  <span className={b('label')}>Currency</span>

  <CurrencySelector value={curFilters.curr ? curFilters.curr[0] : "USD"} onChange={(value) => handleChangeFilter(value, 'curr')} wd={'200px'}/>
    </Col>

  
</Row>
<Row gutter={8} style={{marginBottom: '16px'}}>
<Col>

<span className={b('label')} />
    <Button
      type="primary"
      onClick={handleResetFilters}
      className={b('item')}
      icon={<RedoOutlined />}
    >
      Reset Filters
    </Button>
    </Col>
    <Col >
    <span className={b('label')} />
    <Button
      type="primary"
      onClick={() => onFilter(curFilters)}
      className={b('item')}
      icon={<FilterOutlined />}
    >
      Filter
    </Button>
    
    </Col>
    </Row>
</>
  )
}

export default RedeemOrderFilter;
