import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { Typography, message, Input, Button, Affix } from 'antd'
import useTable from '../../Utils/useTable'
import { cancelPurchaseJobs, purchaseJobsListFetch } from '../../Reducers/purchaseJobs'
import JobFilters from '../../Components/JobFilters';
import {CloseCircleOutlined } from '@ant-design/icons';
import CancelJobButton from '../../Components/CancelJobButton'
import JobsTableWrapper from '../../Components/JobsTableWrapper'

const { Search } = Input

function PurchaseJobs() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.purchaseJobs.list, purchaseJobsListFetch);

  // const [selectedRow, setSelectedRow] = useState([]);

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRow(newSelectedRowKeys);
  // };

  return (
    <>
      <Typography.Title>Purchase Jobs</Typography.Title>
      <JobFilters filters={filters} onFilter={onFilter} className="mb"/>
      <JobsTableWrapper
        jobTableType='purch_jobs_table'
        jobsList={list}
        onChange={onChange}
        pagination={pagination}
        isLoading={isLoading}
        fetch={fetch}
        onFilter={onFilter}
        filters={filters}
      />
    </>
  );
}

export default PurchaseJobs;
