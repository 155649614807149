import React, {useState, useCallback, useEffect, useMemo} from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Tooltip, Tag, message, Table, InputNumber } from 'antd'
import b_ from 'b_'
import ImageViews from '../../Components/ImageViews'
import LinksViews from '../../Components/LinksViews'
import ExpandViews from '../../Components/ExpandViews'
import useStorageState from '../../Utils/useStorageState'
import PrintProxiesStatus from '../../Components/Prints/PrintProxiesStatus'
import UpdateProxies from './EditProxies'
import UpdatePortProxies from './EditPortProxies'
import UpdateCommentProxies from './EditCommentProxies'
import TableSettings from '../../Utils/TableSettings'

const b = b_.lock('ProxiesTable')

function ProxiesTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      onFilter,
                      filters,
                      selected,
                      onSelectChange,
                      handleSave,
                      fieldsChange,
                      setSelectedRow
                    }) {
  const GetColumns = useCallback(() => {

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 52,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Group',
        dataIndex: 'group',
        key: 'group',
        width: 70,
        sorter: (a, b) => a.group.localeCompare(b.group),
      },
      {
        title: 'Type',
        dataIndex: 'proxyType',
        key: 'proxyType',
        width: 70,
        sorter: (a, b) => a.proxyType.localeCompare(b.proxyType),
        render:(type, rec) => (<UpdateProxies
          fieldsChange={fieldsChange}
          updateProxies={handleSave}
          value={type}
          title={"type"}
          rec={rec}/>)
      },
      {
        title: 'Host',
        dataIndex: 'host',
        key: 'host',
        width: 120,
        sorter: (a, b) => a.host.localeCompare(b.host),
        render: (host, rec) => (<UpdateProxies
          fieldsChange={fieldsChange}
          updateProxies={handleSave}
          value={host}
          title={"host"}
          rec={rec}/>)
      },
      {
        title: 'Port',
        dataIndex: 'port',
        key: 'port',
        align: 'center',
        width: 80,
        sorter: (a, b) => a.port - b.port,
        render:(port, rec) => (<UpdatePortProxies
          fieldsChange={fieldsChange}
          updateProxies={handleSave}
          value={port}
          title={"port"}
          rec={rec}/>)
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 80,
        sorter: (a, b) => a.status.localeCompare(b.status),
        render:(status, rec) => (<PrintProxiesStatus
          fieldsChange={fieldsChange}
          handleSave={handleSave}
              status={status}
              field={"status"}
              rec={rec}/>)
      },
       {
        title: 'Accounts',
        dataIndex: 'accounts',
        key: 'accounts',
        align: 'center',
        width: 200,
        render: (_, record) => (
          record.accounts && record.accounts.map((acc, index) => (
            <React.Fragment key={index}>
              {acc}
              {index !== record.accounts.length - 1 && <span>, </span>}
            </React.Fragment>
          ))
        ),
      },
      {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
        width: 80,
        sorter: (a, b) => a.comment.localeCompare(b.comment),
        render:(comment, rec) => (<UpdateCommentProxies
          fieldsChange={fieldsChange}
          updateProxies={handleSave}
          value={comment || "comment"}
          title={"comment"}
          rec={rec}/>)
      },
      {
        title: 'Order',
        dataIndex: 'order',
        key: 'order',
        width: 80,
        // sorter: (a, b) => a - b,
      },
    ]; 
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('proxies_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
});

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < columns.length,
        checkAll: checkedList.length === columns.length,
    });
}, [Checkboxs]);

useEffect(() => {
  let allCheckList = []
  if (list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
          allCheckList.push(e[Object.keys(e)[1]])
          setCheckbox(allCheckList)
      })
  }
}, [list, AllCheckbox, Checkboxs.length, GetColumns])

useEffect(() => {
  if (AllCheckbox.checkAll) {
      setAllCheckbox({
          checkedList: GetColumns().map(e => e.title),
          indeterminate: false,
          checkAll: true,
      })
  }
}, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
        checkedList: (e.target.checked && columns.map(e => e.title)) || [],
        indeterminate: false,
        checkAll: e.target.checked,
    });
}, [Checkboxs]);

  useEffect(() => {
      setColumns(GetColumns())
  }, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const loader = loading

  
  return <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  const key = `${record.id}`
                  setSelectedRow((prev) => prev.includes(key) ? prev.filter((it) => it !== key) : [...prev, key])
                },
              };
            }}
            scrollToFirstRowOnChange={true}
            rowKey={(record) => `${record.id}`}
            className={b()}
            bordered
            dataSource={list}
            scroll={{ x: 'max-content' }}
            size="middle"
            onChange={(a, b, c) => tableOnChange(a, b, c)}
            onFilter={onFilter}
            pagination={pagination}
            loading={loader}
            columns={columnsFilter}
            title={() => <TableSettings
              onChangeCheckAll={onChangeCheckAll}
              allChecks={AllCheckbox}
              Checkboxs={AllCheckbox.checkedList}
              TableColumn={() => GetColumns()}
              setCheckbox={onChangeCheckList}
          />}
            rowClassName={() => b('lock')}
            rowSelection={{
              selectedRowKeys: selected,
              onChange: onSelectChange,
            }}
  />
}

export default ProxiesTable;
