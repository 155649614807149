import { createAction, createReducer } from "@reduxjs/toolkit";
import { frc } from 'front-end-common';
import PurchaseStatisticsService from "../Services/PurchaseStatisticsService";

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const purchaseStatisticsListFetch = createAction('purchaseStatistics/list', (params) => ({
  payload: PurchaseStatisticsService.getPurchaseStatisticsList(params)
}))

const initialState = {
  list: getDefaultState()
}

export default createReducer(initialState, {
  ...getDefaultHandler(purchaseStatisticsListFetch, 'list')
})
