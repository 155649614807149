import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { Typography, message, Input } from 'antd'
import Title from 'antd/lib/typography/Title'
import useTable from '../../Utils/useTable'
import { algoStatisticsListFetch } from '../../Reducers/algoStatistics'
import AlgoStatisticsTable from './AlgoStatisticsTable'
import ExpCountButton from './GroupEditing/ExpCountButton'
import ExpNewButton from './GroupEditing/ExpNewButton'
import AlgoStatFilters from '../../Components/AlgoStatFilters'


const { Search } = Input

function AlgoStatistics() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.algoStatistics.list, algoStatisticsListFetch);

  const [selectedRow, setSelectedRow] = useState([]);
  const [payload, setPayload] = useState([]);

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRow(newSelectedRowKeys);
  // };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const payload = selectedRows.map((row) => ({
      itemId: row.purchItemId.itemId,
      currency: row.purchItemId.currency,
      subscr: row.purchItemId.subscr,
      onSale: row.purchItemId.onSale,
      price: row.price,
    }));
  
    setSelectedRow(selectedRowKeys);
    setPayload(payload);
  };

  // console.log('payload', payload)
  // console.log('payload', selectedRow)

  return (
    <>
      <Typography.Title>Algo Statistics</Typography.Title>
      <div className="buttonsGroup">
      
        <ExpCountButton selected={selectedRow} afterUpdate={fetch} payload={payload} />
        <ExpNewButton selected={selectedRow} afterUpdate={fetch} />
        {
          !!selectedRow.length
          && <span style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginLeft: "15px", marginRight: "4px"}}><b>
            for {selectedRow.length} rows
          </b></span>
        }
      </div>
      <AlgoStatFilters filters={filters} onFilter={onFilter} afterUpdate={fetch} className="mb"/>
      <Search defaultValue={query} placeholder="Input search text" className="mb" size="large" onSearch={onSearch} />
      <AlgoStatisticsTable
        list={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
      />
    </>
  );
}

export default AlgoStatistics;
