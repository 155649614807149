import { createAction, createReducer } from '@reduxjs/toolkit';
import AccountsService from '../Services/AccountsService';
import { frc } from 'front-end-common';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const fetchAccount = createAction('account/list', (id) => ({
  payload: AccountsService.fetchAccount(id),
}));

export const fetchJobsAccount = createAction('account/jobs', (data) => ({
  payload: AccountsService.getJobs(data),
}));

export const fetchOrders = createAction('account/orders', (data) => ({
  payload: AccountsService.fetchOrderHistory(data),
}));



const initState = {
  list: getDefaultState(),
  jobs: getDefaultState(),
  orders: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(fetchAccount, 'list'),
  ...getDefaultHandler(fetchJobsAccount, 'jobs'),
  ...getDefaultHandler(fetchOrders, 'orders'),
});
