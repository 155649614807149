import ApiService from './ApiService';

const RedeemJobsService = {

  getList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        if (key === 'Dates') {
          updatedParams.fromTs = filters[key][0];
          updatedParams.toTs = filters[key][1];
        } else {
          updatedParams[key] = filters[key].join(',');
        }
      }
    }

    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }

    return ApiService.get('/job', { ...updatedParams, jobName: 'Redeem' });
    // return ApiService.get('/job/Redeem/0/50');
  },
};

export default RedeemJobsService
