import { createAction, createReducer } from '@reduxjs/toolkit';
import { frc } from 'front-end-common';
import PurchaseJobsService from '../Services/PurchaseJobsService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const purchaseJobsListFetch = createAction('purchaseJobs/list', (params) => ({
  payload: PurchaseJobsService.getList(params),
}));

export const cancelPurchaseJobs = createAction('purchaseJobs/cancel', (ids) => ({
  payload: PurchaseJobsService.cancel(ids),
}));

const initState = {
  list: getDefaultState(),
  cancel: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(purchaseJobsListFetch, 'list'),
  ...getDefaultHandler(cancelPurchaseJobs, 'cancel'),
});
