import { createAction, createReducer } from "@reduxjs/toolkit";
import { frc } from 'front-end-common';
import LostAndFoundService from "../Services/LostAndFoundService";

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const lostAndFoundListFetch = createAction('lostAndFound/list', (params) => ({
  payload: LostAndFoundService.getLostAndFoundList(params)
}))

export const setLostOrFound = createAction('virtualMachines/edit', (params) => ({
  payload: LostAndFoundService.editLostOrFound(params)
}))

export const groupChangeLostAndfound = createAction('lostAndFound/group-edit', (params) => ({
  payload: LostAndFoundService.groupEditLostAndFound(params)
}))

const initialState = {
  list: getDefaultState(),
  edit: getDefaultState(),
  groupEdit: getDefaultState(),
}

export default createReducer(initialState, {
  ...getDefaultHandler(lostAndFoundListFetch, 'list'),
  ...getDefaultHandler(setLostOrFound, 'edit'),
  ...getDefaultHandler(groupChangeLostAndfound, 'groupEdit')
})
