import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { Typography, message, Input, Button, Affix } from 'antd'
import Title from 'antd/lib/typography/Title'
import AccountGroupBalances from './AccountGroupBalances'
import useTable from '../../Utils/useTable'
import { accountsListFetch, downloadAccounts, getAccountsPoolInfo, getCurrenciesList, updateAccount, updateAccounts } from '../../Reducers/accounts'
import AccountsTable from './AccountsTable'
import CreateAccount from './CreateAccount'
import UpdateAccounts from './UpdateAccounts'
import GroupEnablingButtonGroup from './GroupEditing/GroupEnablingButtonGroup'
import CreateAccounts from "./CreateAccounts";
import AccountsFilters from '../../Components/AccountsFilters'
import Draggable from 'react-draggable';

const { Search } = Input

function Accounts() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.accounts.list, accountsListFetch);

  const fieldsChange = [
    "login",
    "phase",
    "authToken",
    "currency",
    "comment",
    "driver",
    "enabledJobs",
]

  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('newSelectedRowKeys', newSelectedRowKeys)
    setSelectedRow(newSelectedRowKeys);
  };

  console.log('selectedRow', selectedRow)

  const dispatch = useDispatch()

  const updateAccountState = useCallback((data) => {
    dispatch(updateAccount(data)).then(() => {
        message.success('account has been updated!');
        setTimeout(fetch, 1000);
    }).catch(error => {
        console.log(error);
        message.error('Can\'t update Account.');
    });
}, [dispatch, fetch]);

const updateAccountStatus = useCallback((data) => {

  dispatch(updateAccounts(data)).then(() => {
      message.success('account status has been updated!');
      setTimeout(fetch, 1000);
  }).catch(error => {
      console.log(error);
      message.error('Can\'t update Account status.');
  });
}, [dispatch, fetch]);

useEffect(() => {
  dispatch(getAccountsPoolInfo())
}, [dispatch])

useEffect(() => {
  dispatch(getCurrenciesList())
}, [dispatch])

  const accounts = list.accounts && list.accounts

  return (
    <>
      <Typography.Title>Accounts</Typography.Title>
      <div style={{ marginBottom: "20px" }}>
        <Title level={2}>Account Group Balances</Title>
        <AccountGroupBalances className="mb" />
      </div>
      <AccountsFilters filters={filters} onFilter={onFilter} afterUpdate={fetch} className="mb"/>
      <div>
        <Search defaultValue={query} placeholder="Search for account" className="mb" size="large" onSearch={onSearch} />
      </div>
      <Affix id="todrag" offsetTop={-10}>
      <div  className="buttonsGroup_accounts">
        <Button onClick={downloadAccounts}>Download</Button>
        <GroupEnablingButtonGroup selected={selectedRow} afterUpdate={fetch} handleSave={updateAccountState} />
        <UpdateAccounts afterSuccess={fetch} selected={selectedRow} />
        <CreateAccount afterSuccess={fetch} />
        <CreateAccounts afterSuccess={fetch} />
        {
          !!selectedRow.length
          && <span style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginLeft: "15px", marginRight: "4px"}}><b>
            {
              selectedRow.reduce((previousValue, currentValue) => {
                const rowArr = currentValue.split('_')
                return (previousValue + +rowArr[1])
              }, 0).toFixed(0)
            } EUR in {selectedRow.length} rows
          </b></span>
        }
      </div>
      </Affix>
      <AccountsTable
        list={accounts}
        listData={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        setSelectedRow={setSelectedRow}
        onSelectChange={onSelectChange}
        fieldsChange={fieldsChange}
        handleSave={updateAccountState}
        updateAccountStatus={updateAccountStatus}
      />
    </>
  );
}

export default Accounts;
