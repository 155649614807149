export default {
  scheme: 'monokai',
  author: 'wimer hazenberg (http://www.monokai.nl)',
  //background color
  base00: 'transparent',
  base01: '#383830',
  base02: '#49483e',
  base03: '#75715e',
  base04: '#a59f85',
  base05: '#f8f8f2',
  base06: '#f5f4f1',
  base07: '#f9f8f5',
  base08: '#f92672',
  //number + bool
  base09: '#fd971f',
  base0A: '#f4bf75',
  //string
  base0B: '#389e0d',
  base0C: '#a1efe4',
  // keys
  base0D: 'rgba(0, 0, 0, 0.88)',
  base0E: '#ae81ff',
  base0F: '#cc6633',
};
