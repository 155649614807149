import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Input, message, InputNumber, Popconfirm} from 'antd';
import { EditFilled } from '@ant-design/icons';
import b_ from 'b_';
import splitArray from '../../../Utils/splitArray';
import { groupChangeAccountsToCreate } from '../../../Reducers/accountsToCreate';
import { updateAccount } from '../../../Reducers/accounts';
// import { changeRatios } from '../../../Reducers/resell';

const GroupEnablingButtonGroup = ({
                     afterUpdate,
                     selected 
                   }) => {

  const b = b_.lock('DisableButton');

  const dispatch = useDispatch();

  const handleSuccess = useCallback((data) => {

    if (selected.length) {

      if (data.isDone) {
        const handleData = {accountIds: selected, phase: 'Prepare'}

      dispatch(updateAccount(handleData))
      .then(() => {
        message.success('Set done!');
        afterUpdate();
      }).catch((error) => {
        // console.log(error);
        message.error('Can\'t set done.');
      })
    } else {
      const handleData = {accountIds: selected, message: 'set_failed_to_create', requestId: `Failed to create - ${Date.now()}`}

      dispatch(groupChangeAccountsToCreate(handleData))
      .then(() => {
        message.success('Set failed to create!');
        afterUpdate();
      }).catch((error) => {
        // console.log(error);
        message.error('Can\'t set failed to create.');
      })
    };
    }
  }, [dispatch, afterUpdate, selected]);
  
  return (
    <div style={{marginRight: '16px'}}>
      <Popconfirm
          title={`Are you sure to set done ${selected.length} ${selected.length === 1 ? 'account' : 'accounts'}?`}
          onConfirm={() => handleSuccess({'isDone': true})}
          okText="Yes"
          cancelText="No"
        >
      <Button
        type="primary"
        disabled={!selected.length}
        style={{marginRight: '16px'}}
      >
        Done
      </Button>
      </Popconfirm>
    <Popconfirm
          title={`Are you sure to set fail ${selected.length} ${selected.length === 1 ? 'account' : 'accounts'}?`}
          onConfirm={() => handleSuccess({'isDone': false})}
          okText="Yes"
          cancelText="No"
        >
      <Button
        type="primary"
        danger
        disabled={!selected.length}
      >
        Failed
      </Button>
      </Popconfirm>
    </div>
  );
};

export default React.memo(GroupEnablingButtonGroup);
