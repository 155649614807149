import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import Title from 'antd/lib/typography/Title';
import { fetchAccount, fetchJobsAccount } from '../../Reducers/account';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AccountTable from './AccountTable';
import './styles.scss';
import { fetchAuxAccount, updateAccount, updateAccounts } from '../../Reducers/accounts';
import useTable from '../../Utils/useTable';
import { Affix, message } from 'antd';
import CancelJobButton from '../../Components/CancelJobButton';
import JobsTableWrapper from '../../Components/JobsTableWrapper';

const b = b_.lock('AccountPage');

function Account() {
  // const { list } = useTable(state => state.account.list, fetchAccount);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const { payload, isLoading } = useSelector(state => state.account.list, shallowEqual)

  console.log('data', payload)

  const dispatch = useDispatch();
  const params = useParams();

  console.log('params', params)

  const fetchJobs = useCallback((data) => fetchJobsAccount(params.id ), []);
  const { fetch, ...props } = useTable(
    state => state.account.jobs,
    fetchJobs,
  );
  
  useEffect(() => {
    dispatch(fetchAccount(params.id));
    // dispatch(fetchAuxAccount());
    // dispatch(fetchClientsList());
  }, [dispatch]);

  const afterUpdate = useCallback(() => {
    setTimeout(() =>  {
      dispatch(fetchAccount(params.id));
      fetch();
    }, 1000)
  }, [fetch, dispatch, params]);

  const updateAccountStatus = useCallback((data) => {
    dispatch(updateAccounts(data))
    .then(() => {
      message.success("Succesfully updated account status")
    })
    .catch((e) => {
      message.error("Failed to update account status")
    })
  }, [dispatch])

  const jobsList = props.list && props.list

  return <div>
    <Title className="mb">Account-details - {params.id}</Title>
    <AccountTable isLoading={isLoading}
                  listData={payload}
                  data={(payload && payload.accounts) || null}
                  afterUpdate={afterUpdate}
                  className="mb"
                  updateAccountStatus={updateAccountStatus}
    />
    <Title className={`mb ${b('title')}`}>
      Jobs
    </Title>
    <JobsTableWrapper
        {...props}
        jobTableType='acc_jobs_table'
        jobsList={jobsList}
        loading={isLoading}
        afterUpdate={fetch}
      />
  </div>;
}

export default Account;
