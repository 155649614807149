import ApiService from './ApiService';

export default {
  getList() {
    return ApiService.get('/config');
  },
  updateConfig(data) {
    return ApiService.put('/config/update', data);
  },
}
