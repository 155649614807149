import ApiService from './ApiService';

const VirtualMachinesService = {
  getVirtualMachinesList({ orderPath, ...params }) {
    const updatedParams = { ...params };
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '')
    }
    return ApiService.get('/vm', updatedParams)
    // return ApiService.get('/vm')
  },

  createVirtualMachine(params) {
    return ApiService.post('/vm', params)
  },

  editVirtualMachine(params) {
    return ApiService.put('/vm', params)
  },

  // TO DO
  groupEditVirtualMachines(params) {
    return ApiService.put('/vm/group', params)
  },
};

export default VirtualMachinesService
