import ApiService from './ApiService';

const AccountsToCreateService = {
  getAccountsToCreateList({ orderPath, ...params }) {
    const updatedParams = { ...params };
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '')
    }
    return ApiService.get('/accounts_to_create', updatedParams)
  },

  groupEditAccountsToCreate(params) {
    return ApiService.post('/accounts/set_failed_to_create', params)
  },
};

export default AccountsToCreateService
