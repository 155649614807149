import React, { useState } from 'react'
import { Affix } from 'antd';
import CancelJobButton from '../CancelJobButton';
import CarouselView from '../ViewsChildren/CarouselView';
import ListView from '../ViewsChildren/List';
import { EyeOutlined, GlobalOutlined } from '@ant-design/icons';
import { frc } from 'front-end-common';

const JobsTableWrapper = ({ 
  jobTableType, 
  jobsList, 
  isLoading, 
  fetch,
  filters,
  onChange,
  pagination,
  onFilter
}) => {

  const { JobsTable } = frc.components

  const jobsDataIndices = {
    // titles
    time: "creationTime", 
    update: "updateTime", 
    account: "assignedAccLogin", 
    driver: "vmId",
    proxy: "proxy",
    ipAddress: "ipAddressHistory",
    result: "result",
    screenshots: "screenshots",
    action: "action",
    details: "details",
    // components
    accountGroupName: "accountGroupName",
    assignedAccId: "assignedAccId"
  }

  const resultBackground = {
    NGN_PURCH_NOT_LOADED: "rgb(252, 248, 227)"
  }

  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  const childrenToModalData = [
    {
      id: 1,
      data: 'screenshots',
      ViewComponent: CarouselView,
      Icon: <EyeOutlined />,
      dimensions: { width: "90vw", height: "90vh" }
    },
    {
      id: 2,
      data: 'pageSources',
      ViewComponent: ListView,
      Icon: <GlobalOutlined />,
      dimensions: { width: "50vw", height: "50vh" }
    },
  ];

  return (
    <>
    <Affix id="todrag" offsetTop={0}><CancelJobButton isLoading={isLoading} selectedRow={selectedRow} fetch={fetch} setSelectedRow={setSelectedRow} /></Affix>
    <JobsTable
        jobTableType={jobTableType}
        jobsDataIndices={jobsDataIndices}
        onChange={onChange}
        pagination={pagination}
        onFilter={onFilter}
        filters={filters}
        list={jobsList}
        loading={isLoading}
        afterUpdate={fetch}
        selected={selectedRow}
        onSelectChange={onSelectChange}
        setSelectedRow={setSelectedRow}
        childrenForModal={childrenToModalData}
        resultBackground={resultBackground}
    />
    </>
  )
}

export default JobsTableWrapper
