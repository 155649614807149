import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { Typography, message, Input } from 'antd'
import Title from 'antd/lib/typography/Title'
import useTable from '../../Utils/useTable'
import { redeemOrderListFetch } from '../../Reducers/redeemOrder'
import RedeemOrderTable from './RedeemOrderTable'
import RedeemOrderFilter from '../../Components/RedeemOrderFilter'
import { getCurrenciesList, updateAccount } from '../../Reducers/accounts'

const { Search } = Input

function RedeemOrder() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.redeemOrder.list, redeemOrderListFetch);

  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  const fieldsChange = [
    "login",
    "phase",
    "authToken",
    "currency",
    "comment",
    "driver",
    "enabledJobs",
]

  // console.log('list', list)
  // console.log('query', query)

  const dispatch = useDispatch()

  const updateAccountState = useCallback((data) => {
    dispatch(updateAccount(data)).then(() => {
        message.success('account has been updated!');
        setTimeout(fetch, 1000);
    }).catch(error => {
        console.log(error);
        message.error('Can\'t update Account.');
    });
}, [dispatch, fetch]);

useEffect(() => {
  dispatch(getCurrenciesList())
}, [dispatch])

  return (
    <>
      <Typography.Title>Redeem order</Typography.Title>
      <RedeemOrderFilter filters={filters} onFilter={onFilter} className="mb"/>
      {/* <Search defaultValue={query} placeholder="Input search text" className="mb" size="large" onSearch={onSearch} /> */}
      <RedeemOrderTable
        list={list.accounts}
        listData={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
        fieldsChange={fieldsChange}
        handleSave={updateAccountState}
      />
    </>
  );
}

export default RedeemOrder;
