import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CarouselView = ({ data }) => {
  return (
    <Carousel showArrows={true}>
                      {data?.map((img, key) => (
                        <img
                          key={img}
                          src={BACKEND_URL + img}
                          style={{ maxHeight: "70vh", pointerEvents: "unset", objectFit: "contain" }}
                          alt="screenshot"
                        />
                      ))}
                    </Carousel>
  )
}

export default CarouselView
