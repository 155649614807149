import React from 'react';
import { Tag } from 'antd';

function PrintAccountsStatus({ className, status, rec }) {

  return (
        <Tag
          className={rec.processing ? "processing" : [className, status?.toLowerCase()]}
          >
            {rec.processing ? 'processing' : status}
        </Tag>
  )
}

export default PrintAccountsStatus;
