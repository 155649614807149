import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Input, message, InputNumber, Select} from 'antd';
import { EditFilled } from '@ant-design/icons';
import b_ from 'b_';
import { dearchive, setPhaseForArchiveAccounts } from '../../../Reducers/archiveAccounts';
// import { changeRatios } from '../../../Reducers/resell';

const { Option } = Select

const SetPhaseButton = ({
                     afterUpdate,
                     selected,
                   }) => {

  const b = b_.lock('SetPhaseButton');

  const dispatch = useDispatch();

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const reducedIds = selected.reduce((acc, row) => {
    const splitRow = row.split('_');
    if (splitRow.length > 0) {
      const number = parseInt(splitRow[0]);
      acc.push(number);
    }
    return acc;
  }, []);


  const handleSuccess = useCallback((data) => {
    console.log(data)

    if(reducedIds) {
      const params = {
          accountIds: reducedIds
      }

      dispatch(dearchive({...params, ...data}))
    .then((res) => {
      message.success('Successfully updated phase')
      afterUpdate()
    })
    .catch((error) => {
      message.error(`Failed to set phase: ${error}`)
    })
    }
  }, [dispatch, afterUpdate, close, selected]);
  
  return (
    <>
      <Drawer visible={isOpen} title="Set phase" width={300} closable placement="right" onClose={close}>
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
        {/* <Form.Item
            label="New maximum ratio"
            name="maxRatio">
            <InputNumber />
          </Form.Item> */}
          <Form.Item
            label="Value"
            name="phase"  
            rules={[
              {
                required: true,
                message: 'please select value',
              },
          ]}>
            <Select
                className="item"
              >
                <Option value="Create">Create</Option>
                <Option value="Prepare">Prepare</Option>
                <Option value="Redeem">Redeem</Option>
                <Option value="Purchase">Purchase</Option>
              </Select>
          </Form.Item>
          <Form.Item>
            <Button
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <Button
        type="primary"
        disabled={!selected.length}
        // icon={<EditFilled />}
        onClick={open}
      >
        Set Phase
      </Button>
    </>
  );
};

export default React.memo(SetPhaseButton);
