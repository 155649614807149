import React, { useEffect } from 'react';
import { Select } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {fetchAccountsGroups} from "../../Reducers/accounts";

function GroupSelector({ className, value, onChange, mode }) {
  const groups = useSelector((state) => state.accounts.accountsGroups.payload, shallowEqual);
  console.log('groups', groups)
  const groupsForTest = ["EUR", "USD", "PROXY_1"]
  const dispatch = useDispatch();

  useEffect(() => {
    if (!groups) {
      dispatch(fetchAccountsGroups());
    }
  }, [dispatch, groups]);

  return (
    <Select
      mode={mode}
      className={className}
      value={value}
      onChange={onChange}
      style={{ width: '100%' }}
      allowClear
    >
      {groupsForTest && groupsForTest.map(group => <Select.Option key={group} value={group}>{group}</Select.Option>)}
    </Select>);
}

export default GroupSelector;
