import ApiService from './ApiService';

const ArchiveAccountsService = {
  // getArchiveAccountsList({ orderPath, ...params }) {
  //   const updatedParams = { ...params };
  //   if (orderPath) {
  //     updatedParams.order = orderPath.replace('end', '')
  //   }
  //   return ApiService.get('/account/archive', updatedParams)
  // },

  getArchiveAccountsList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/account/archive', updatedParams);
  },

  setPhaseForAccounts(data) {
    const updatedData = Object.keys(data).reduce((newData, key) => {
      if (![null, undefined].includes(data[key])) {
        newData[key] = data[key];
      }
      return newData;
    }, {});

    return ApiService.post('/accounts/update', updatedData);
  },

  // dearchiveAccount(id) {
  //   return ApiService.post('/account/dearchive', { accountId: id, phase: 'Create', requestId: Date.now() })
  // },

  dearchiveAccount(data) {
    const updatedData = Object.keys(data).reduce((newData, key) => {
      if (![null, undefined].includes(data[key])) {
        newData[key] = data[key];
      }
      return newData;
    }, {});

    return ApiService.patch('/accounts/dearchive', { ...updatedData, requestId: Date.now() });
  },
};

export default ArchiveAccountsService
