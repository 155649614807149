import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Tooltip, Input, message, InputNumber} from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import b_ from 'b_';
// import { changeRatios } from '../../../Reducers/resell';

const AuthKeyButton = ({
                     record, 
                     afterUpdate,
                     title,
                     updatedValue,
                     icon,
                     field,
                     handleSave,
                     initialValue
                   }) => {

  const b = b_.lock('AuthKeyButton');

  const dispatch = useDispatch();

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  // const [inputValue, handleChange] = useState("");

  const handleSuccess = useCallback((data) => {
    console.log(data)
  }, [dispatch, afterUpdate, close]);

  const handleSaveUp = (data) => {
    if(record) {
        const params = {
            accountIds: [
              record.id
            ]
        }
        
        handleSave({...params, ...data});
        close();
        return
    }
    
    close()
    return;
}
  
  return (
    <>
      <Drawer visible={isOpen} title={title} width={300} closable placement="right" onClose={close}>
        <Form layout="vertical" className={b()} onFinish={handleSaveUp}>
          <Form.Item
            label={`New ${title}`}
            name={updatedValue} 
            rules={[
              {
                required: true,
                message: `please enter new ${title}`,
              },
          ]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <Tooltip placement="topLeft" title={`Change ${title}`}>
        <Button
          type="primary"
          // disabled={!selected.length}
          // icon={<KeyOutlined />}
          icon={icon}
          onClick={open}
        />
      </Tooltip>
    </>
  );
};

export default React.memo(AuthKeyButton);
