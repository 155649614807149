import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Input, message, InputNumber, Popconfirm} from 'antd';
import { EditFilled } from '@ant-design/icons';
import b_ from 'b_';
import splitArray from '../../../Utils/splitArray';
import { groupChangeVirtualMachines } from '../../../Reducers/virtualMachines';
// import { changeRatios } from '../../../Reducers/resell';

const GroupEnablingButtonGroup = ({
                     afterUpdate,
                     selected,
                     payload
                   }) => {

  const b = b_.lock('DisableButton');

  const dispatch = useDispatch();

  const handleSuccess = useCallback((data) => {
    console.log('data', data)
    console.log('selected', selected)

    if (selected.length) {
      const newArr = selected.map((el) => el.split(','))
      console.log('newArr', newArr)
      const preparedArr = splitArray(newArr)
      console.log('preparedArr', preparedArr)

      const handleData = payload.map((item) => {
        return {...item, ...data}
      })
      console.log('handleData', handleData)

      dispatch(groupChangeVirtualMachines(handleData))
      .then(() => {
        message.success('virtual machines have been changed!');
        afterUpdate();
      }).catch((error) => {
        // console.log(error);
        message.error('Can\'t update virtual machines.');
      });
    }
  }, [dispatch, afterUpdate, selected]);
  
  return (
    <div style={{marginRight: '16px'}}>
      <Popconfirm
          title={`Are you sure to enable ${selected.length} ${selected.length === 1 ? 'product' : 'products'}?`}
          onConfirm={() => handleSuccess({'status': "Enabled"})}
          okText="Yes"
          cancelText="No"
        >
      <Button
        type="primary"
        disabled={!selected.length}
        style={{marginRight: '16px'}}
      >
        Enable
      </Button>
      </Popconfirm>
    <Popconfirm
          title={`Are you sure to disable ${selected.length} ${selected.length === 1 ? 'product' : 'products'}?`}
          onConfirm={() => handleSuccess({'status': "Disabled"})}
          okText="Yes"
          cancelText="No"
        >
      <Button
        type="primary"
        disabled={!selected.length}
      >
        Disable
      </Button>
      </Popconfirm>
    </div>
  );
};

export default React.memo(GroupEnablingButtonGroup);
