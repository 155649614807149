import React, { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import { Typography, message, Input } from 'antd'
import Title from 'antd/lib/typography/Title'
import useTable from '../../Utils/useTable'
import { changeVirtualMachine, virtualMachinesListFetch } from '../../Reducers/virtualMachines'
import VirtualMachinesTable from './VirtualMachinesTable'
import UpdateButton from './GroupEditing/UpdateButton'
import UpdateVPNButton from './GroupEditing/UpdateVPNButton'
import EnableButton from './GroupEditing/EnableButton'
import DisableButton from './GroupEditing/DisableButton'
import NewButton from './GroupEditing/NewButton'
import GroupEnablingButtonGroup from './GroupEditing/GroupEnablingButtonGroup'
import { fetchAccountsGroups } from '../../Reducers/accounts'



const { Search } = Input

function VirtualMachines() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.virtualMachines.list, virtualMachinesListFetch);

  const fieldsChange = [
    "id",
    "name",
    "host",
    "driverPort",
    "groupName",
    "status",
    "engineId",
    "uses"
]

  const [selectedRow, setSelectedRow] = useState([]);

  const [payload, setPayload] = useState([]);
  
  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const payload = selectedRows.map((row) => ({
        id: row.id,
        name: row.name,
        host: row.host,
        driverPort: row.driverPort,
        engineId: row.engineId,
        groupName: row.groupName,
        uses: row.uses
    }));
  
    setSelectedRow(selectedRowKeys);
    setPayload(payload);
  };

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRow(newSelectedRowKeys);
  // };

  const dispatch = useDispatch()

  const updateVirtualMachine = useCallback((params) => {

    console.log('params', params)
    dispatch(changeVirtualMachine(params)).then((e) => {
        try {
            message.success('Proxies completed update.');
            fetch()
        } catch (e) {
            message.error(`Can't update Proxies: ${e}`);
            fetch()
        }
    })
}, [dispatch, fetch]);

const groups = useSelector(state => state.accounts.accountsGroups.payload, shallowEqual);

  useEffect(() => {
      !groups && dispatch(fetchAccountsGroups());
  },[dispatch, groups])

// const { payload } = useSelector(state => state.virtualMachines.list, shallowEqual)

console.log('groups', groups)
console.log('list', list)
// console.log('updatedList', payload)

// const list = payload && payload


  return (
    <>
      <Typography.Title>Virtual Machines</Typography.Title>

      <div className="buttonsGroup">
        {/* <EnableButton selected={selectedRow} afterUpdate={fetch} />
        <DisableButton selected={selectedRow} afterUpdate={fetch} /> */}
        <GroupEnablingButtonGroup selected={selectedRow} afterUpdate={fetch} payload={payload} />
        <NewButton selected={selectedRow} afterUpdate={fetch} groups={groups} />
        {/* <UpdateButton selected={selectedRow} afterUpdate={fetch} /> */}
        {/* <UpdateVPNButton selected={selectedRow} afterUpdate={fetch} /> */}
        
        {
          !!selectedRow.length
          && <span style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginLeft: "15px", marginRight: "4px"}}><b>
            for {selectedRow.length} rows
          </b></span>
        }
      </div>
        {/* <Search defaultValue={query} placeholder="Input search text" className="mb" size="large" onSearch={onSearch} /> */}
      <VirtualMachinesTable
        list={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
        handleSave={updateVirtualMachine}
        fieldsChange={fieldsChange}
        setSelectedRow={setSelectedRow}
      />
    </>
  );
}

export default VirtualMachines;
