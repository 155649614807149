import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Tooltip, Input, message, InputNumber} from 'antd';
import { GiftOutlined } from '@ant-design/icons';
import b_ from 'b_';
import { updateAccounts } from '../../../Reducers/accounts';
// import { changeRatios } from '../../../Reducers/resell';

const FetchJobButton = ({
                     record,
                     afterUpdate,
                     jobName,
                     icon,
                     title
                   }) => {

  const b = b_.lock('CheckPerksButton');

  const dispatch = useDispatch();

  const handleSuccess = useCallback((data) => {
    console.log(data)
    dispatch(updateAccounts({"accountId": record.id, "name": jobName, "requestId": Date.now().toString()}))
    .then(() => {
      message.success('Successfuly fetched job action')
      afterUpdate()
    })
    .catch((error) => {
      message.error(`Failed to fetch: ${error}`)
    })
  }, [dispatch, afterUpdate, jobName, record]);
  
  return (
    <>
      <Tooltip placement="topLeft" title={title}>
        <Button
          type="primary"
          icon={icon}
          onClick={handleSuccess}
        />
      </Tooltip>
    </>
  );
};

export default React.memo(FetchJobButton);
