import { createAction, createReducer } from "@reduxjs/toolkit";
import { frc } from 'front-end-common';
import AlgoStatisticsService from "../Services/AlgoStatisticsService";

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const algoStatisticsListFetch = createAction('algoStatistics/list', (params) => ({
  payload: AlgoStatisticsService.getAlgoStatisticsList(params)
}))

export const algoStatCreate = createAction('algoStatistics/create', (params) => ({
  payload: AlgoStatisticsService.createAlgoStat(params)
}))

export const groupChangeAlgoStat = createAction('algoStatistics/group-edit', (params) => ({
  payload: AlgoStatisticsService.groupEditAlgoStat(params)
}))

const initialState = {
  list: getDefaultState(),
  create: getDefaultState(),
  groupEdit: getDefaultState(),
}

export default createReducer(initialState, {
  ...getDefaultHandler(algoStatisticsListFetch, 'list'),
  ...getDefaultHandler(algoStatCreate, 'create'),
  ...getDefaultHandler(groupChangeAlgoStat, 'groupEdit')
})
