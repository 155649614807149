import { createAction, createReducer } from "@reduxjs/toolkit";
import { frc } from 'front-end-common';
import RedeemOrderService from "../Services/RedeemOrderService";

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const redeemOrderListFetch = createAction('redeemOrder/list', (params) => ({
  payload: RedeemOrderService.getRedeemOrderList(params)
}))

const initialState = {
  list: getDefaultState()
}

export default createReducer(initialState, {
  ...getDefaultHandler(redeemOrderListFetch, 'list')
})
