import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { Typography, message, Input } from 'antd'
import Title from 'antd/lib/typography/Title'
import useTable from '../../Utils/useTable'
import { purchaseStatisticsListFetch } from '../../Reducers/purchaseStatistics'
import PurchaseStatisticsTable from './PurchaseStatisticsTable'
import PurchaseStatFilters from '../../Components/PurchaseStatFilters'

const { Search } = Input

function PurchaseStatistics() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.purchaseStatistics.list, purchaseStatisticsListFetch);

  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  return (
    <>
      <Typography.Title>Purchase Statistics</Typography.Title>
      <PurchaseStatFilters filters={filters} onFilter={onFilter} className="mb"/>
      <Search defaultValue={query} placeholder="Input search text" className="mb" size="large" onSearch={onSearch} />
      <PurchaseStatisticsTable
        list={list}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
      />
    </>
  );
}

export default PurchaseStatistics;
