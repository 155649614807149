import { createAction, createReducer } from "@reduxjs/toolkit";
import { frc } from 'front-end-common';
import AccountsToCreateService from "../Services/AccountsToCreateService";

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const accountsToCreateListFetch = createAction('accountsToCreate/list', (params) => ({
  payload: AccountsToCreateService.getAccountsToCreateList(params)
}))

export const groupChangeAccountsToCreate = createAction('accountsToCreate/group-edit', (params) => ({
  payload: AccountsToCreateService.groupEditAccountsToCreate(params)
}))

const initialState = {
  list: getDefaultState(),
  groupEdit: getDefaultState(),
}

export default createReducer(initialState, {
  ...getDefaultHandler(accountsToCreateListFetch, 'list'),
  ...getDefaultHandler(groupChangeAccountsToCreate, 'groupEdit')
})
