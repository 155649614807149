import React from 'react'
import {
  useRoutes
} from "react-router-dom"
import {
  UserOutlined,
  UnorderedListOutlined,
  ProfileOutlined,
  AccountBookOutlined,
  BarChartOutlined,
  BookOutlined,
  GlobalOutlined,
  LineChartOutlined,
  ReadOutlined,
  SettingOutlined,
  ShopOutlined,
  DesktopOutlined,
  FormOutlined,
  CompassOutlined,
  UserAddOutlined,

} from "@ant-design/icons"

import RedirectGuestToSignInPage from '../Components/RedirectGuestToSignInPage'
import RedirectUserToMainPage from '../Components/RedirectUserToMainPage'
import RedirectPage from '../Pages/RedirectPage'
import Accounts from '../Pages/Accounts'
import PurchaseJobs from '../Pages/PurchaseJobs'
import RedeemJobs from '../Pages/RedeemJobs'
import OtherJobs from '../Pages/OtherJobs'
import ArchiveAccounts from '../Pages/ArchiveAccounts'
import VirtualMachines from '../Pages/VirtualMachines'
import Proxies from '../Pages/Proxies'
import PurchaseStatistics from '../Pages/PurchaseStatistics'
import RedeemOrder from '../Pages/RedeemOrder'
import AlgoStatistics from '../Pages/AlgoStatistics'
import LostAndFound from '../Pages/LostAndFound'
import AccountsToCreate from '../Pages/AccountsToCreate'
import Config from '../Pages/Config'
import Account from '../Pages/Account'
import AccountOrderHistory from '../Pages/AccountOrderHistory'
import AccountAccess from '../Pages/AccountAccess'


function Routes() {
  const element = useRoutes([{
    path: '/sign-in',
    exact: true,
    element: <RedirectUserToMainPage />,
  }, {
    path: '/redirect',
    exact: true,
    element: <RedirectPage />,
  },
  {
    path: '/',
    element: <RedirectGuestToSignInPage />,
    children: [{
      path: '/',
      exact: true,
      element: <Accounts />,
    },
    {
      path: '/purchase-jobs',
      exact: true,
      element: <PurchaseJobs />,
    },
    {
      path: '/redeem-jobs',
      exact: true,
      element: <RedeemJobs />,
    },
    {
      path: '/other-jobs',
      exact: true,
      element: <OtherJobs />,
    },
    {
      path: '/archive-accounts',
      exact: true,
      element: <ArchiveAccounts />,
    },
    {
      path: '/virtual-machines',
      exact: true,
      element: <VirtualMachines />,
    },
    {
      path: '/proxies',
      exact: true,
      element: <Proxies />,
    },
    {
      path: '/purchase-statistics',
      exact: true,
      element: <PurchaseStatistics />,
    },
    {
      path: '/redeem-order',
      exact: true,
      element: <RedeemOrder />,
    },
    {
      path: '/algo-statistics',
      exact: true,
      element: <AlgoStatistics />,
    },
    {
      path: '/lost-and-found',
      exact: true,
      element: <LostAndFound />,
    },
    {
      path: '/accounts_to_create',
      exact: true,
      element: <AccountsToCreate />,
    },
    {
      path: '/config',
      exact: true,
      element: <Config />,
    },
    {
      path: '/account/:id',
      exact: true,
      element: <Account />,
    },
    {
      path: '/account/ord_hist/:id',
      exact: true,
      element: <AccountOrderHistory />,
    },
    {
      path: '/account/access/:id',
      exact: true,
      element: <AccountAccess />
    }
      // {
      //   path: '/drivers',
      //   exact: true,
      //   element: <Driver />,
      // },
      // {
      //   path: '/proxy/:id',
      //   exact: true,
      //   element: <ProxyPage />,
      // },
    ],
  },
  ]);
  return element;
}

export const links = [{
  href: '/',
  icon: <UserOutlined />,
  title: 'Account',
},
{
  href: '/purchase-jobs',
  icon: <AccountBookOutlined />,
  title: 'Purchase Jobs',
},
{
  href: '/redeem-jobs',
  icon: <ReadOutlined />,
  title: 'Redeem Jobs',
},
{
  href: '/other-jobs',
  icon: <UnorderedListOutlined />,
  title: 'Other Jobs',
},
{
  href: '/archive-accounts',
  icon: <BookOutlined />,
  title: 'Archive accounts',
},
{
  href: '/virtual-machines',
  icon: <DesktopOutlined />,
  title: 'Virtual Machines',
},
{
  href: '/proxies',
  icon: <GlobalOutlined />,
  title: 'Proxies',
},
{
  href: '/purchase-statistics',
  icon: <BarChartOutlined />,
  title: 'Purchase statistics',
},
{
  href: '/redeem-order',
  icon: <FormOutlined />,
  title: 'Redeem order',
},
{
  href: '/algo-statistics',
  icon: <BarChartOutlined />,
  title: 'Algo statistics',
},
{
  href: '/lost-and-found',
  icon: <CompassOutlined />,
  title: 'Lost and found',
},
{
  href: '/accounts_to_create',
  icon: <UserAddOutlined />,
  title: 'Accounts to create',
},
{
  href: '/config',
  icon: <ProfileOutlined />,
  title: 'Config',
},
];

export default Routes;
