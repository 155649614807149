import React, {useState, useCallback, useEffect, useMemo} from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Tooltip, Tag, message, Table, InputNumber } from 'antd'
import b_ from 'b_'
import ImageViews from '../../Components/ImageViews'
import LinksViews from '../../Components/LinksViews'
import ExpandViews from '../../Components/ExpandViews'
import useStorageState from '../../Utils/useStorageState'
import TableSettings from './TableSettings'
import PrintDate from '../../Components/Prints/PrintDate'
import { getStringValue } from '../../Utils/subscriptionMask'
import { SortAscendingOutlined } from '@ant-design/icons'

const b = b_.lock('PurchaseStatisticsTable')

function PurchaseStatisticsTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      onFilter,
                      filters,
                      selected,
                      onSelectChange,
                    }) {
  const GetColumns = useCallback(() => {

    return [
      {
        title: 'Item',
        dataIndex: 'itemId',
        key: 'itemId',
        width: 300,
        sorter: (a, b) => a.state.localeCompare(b.state),
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        width: 90,
        sorter: (a, b) => a.state.localeCompare(b.state),
        filters: [
          {
            text: 'USD',
            value: 'USD',
          },
          {
            text: 'EUR',
            value: 'EUR',
          },
        ],
        filteredValue: filters['currency'] || null,

      },
      {
        title: 'Subscr',
        dataIndex: 'subscr',
        key: 'subscr',
        width: 80,
        sorter: (a, b) => a.state.localeCompare(b.state),
        filters: [
          {
            text: 'No',
            value: 'No',
          },
          {
            text: 'Pass',
            value: 'Pass',
          },
          {
            text: 'EA',
            value: 'EA',
          },
          {
            text: 'Pass, EA',
            value: 'Pass, EA',
          },
          {
            text: 'Pass, Full',
            value: 'Pass, Full',
          },
        ],
        filteredValue: filters['subscr'] || null,
        render: (_, record) => {
          if (record.subscr) {
          return <div> {getStringValue(record.subscr)} </div>
        } else {
        return <div>{''}</div>
        }
      }
      },
      {
        title: 'On sale',
        dataIndex: 'onSale',
        key: 'onSale',
        width: 120,
        sorter: (a, b) => a - b,
        filters: [
          {
            text: 'True',
            value: 'true',
          },
          {
            text: 'False',
            value: 'false',
          },
        ],
        filteredValue: filters['onSale'] || null,
        render: (_, record) => {
          return record.onSale.toString();
        },
      },
      {
        title: '30 day count',
        dataIndex: 'count30',
        key: 'count30',
        width: 80,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.count30) {
            return record.count30;
          } else {
            return 0;
          }
        },
      },
      {
        title: '30 day sum',
        dataIndex: 'sum30',
        key: 'sum30',
        width: 80,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.sum30) {
            return record.sum30;
          } else {
            return 0;
          }
        },
      },
      {
        title: '7 day count',
        dataIndex: 'count7',
        key: 'count7',
        width: 90,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.count7) {
            return record.count7;
          } else {
            return 0;
          }
        },
      },
      {
        title: '7 day sum',
        dataIndex: 'sum7',
        key: 'sum7',
        width: 80,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.sum7) {
            return record.sum7;
          } else {
            return 0;
          }
        },
      },
      {
        title: '1 day count',
        dataIndex: 'count1',
        key: 'count1',
        width: 90,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.count1) {
            return record.count1;
          } else {
            return 0;
          }
        },
      },
      {
        title: '1 day sum',
        dataIndex: 'sum1',
        key: 'sum1',
        width: 80,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.sum1) {
            return record.sum1;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Today count',
        dataIndex: 'count0',
        key: 'count0',
        width: 90,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.count0) {
            return record.count0;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Today sum',
        dataIndex: 'sum0',
        key: 'sum0',
        width: 80,
        sorter: (a, b) => a - b,
        render: (_, record) => {
          if (record.sum0) {
            return record.sum0;
          } else {
            return 0;
          }
        },
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        width: 80,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Price time',
        dataIndex: 'time',
        key: 'time',
        width: 80,
        sorter: (a, b) => a - b,
        render: (_, record) => <PrintDate withTime date={record.time} />,
      },
      {
        title: 'Accs can buy',
        dataIndex: 'accsCanBuy',
        key: 'accsCanBuy',
        width: 100,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Accs no money',
        dataIndex: 'accsNoMoney',
        key: 'accsNoMoney',
        width: 100,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Games can buy',
        dataIndex: 'gamesCanBuy',
        key: 'gamesCanBuy',
        width: 100,
        // sortDirections: ['asc', 'desc'],
        // sortIcon: <SortAscendingOutlined />,
        sorter: (a, b) => a - b,
        
      },
      {
        title: 'Max games can buy',
        dataIndex: 'maxGamesCanBuy',
        key: 'maxGamesCanBuy',
        width: 120,
        sorter: (a, b) => a - b,
      },
    ]; 
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('purch_stat_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
});

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < columns.length,
        checkAll: checkedList.length === columns.length,
    });
}, [Checkboxs]);

useEffect(() => {
  let allCheckList = []
  if (list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
          allCheckList.push(e[Object.keys(e)[1]])
          setCheckbox(allCheckList)
      })
  }
}, [list, AllCheckbox, Checkboxs.length, GetColumns])

useEffect(() => {
  if (AllCheckbox.checkAll) {
      setAllCheckbox({
          checkedList: GetColumns().map(e => e.title),
          indeterminate: false,
          checkAll: true,
      })
  }
}, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
        checkedList: (e.target.checked && columns.map(e => e.title)) || [],
        indeterminate: false,
        checkAll: e.target.checked,
    });
}, [Checkboxs]);


  useEffect(() => {
      setColumns(GetColumns())
  }, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const loader = loading

  
  return <Table
            rowKey={(record) => `${record.id}_${record.balance_eur}`}
            className={b()}
            bordered
            dataSource={list}
            scroll={{ x: 'max-content' }}
            size="middle"
            onChange={(a, b, c) => tableOnChange(a, b, c)}
            onFilter={onFilter}
            pagination={pagination}
            loading={loader}
            columns={columnsFilter}
            rowClassName={() => b('lock')}
            title={() => <TableSettings
              onChangeCheckAll={onChangeCheckAll}
              allChecks={AllCheckbox}
              Checkboxs={AllCheckbox.checkedList}
              TableColumn={() => GetColumns()}
              setCheckbox={onChangeCheckList}
          />}
            rowSelection={{
              selectedRowKeys: selected,
              onChange: onSelectChange,
            }}
  />
}

export default PurchaseStatisticsTable;
