import { createAction, createReducer } from '@reduxjs/toolkit';
import { frc } from 'front-end-common';
import OtherJobsService from '../Services/OtherJobsService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const otherJobsListFetch = createAction('otherJobs/list', (params) => ({
  payload: OtherJobsService.getList(params),
}))

export const cancelOtherJobs = createAction('otherJobs/cancel', (ids) => ({
  payload: OtherJobsService.cancel(ids),
}));

const initState = {
  list: getDefaultState(),
  cancel: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(otherJobsListFetch, 'list'),
  ...getDefaultHandler(cancelOtherJobs, 'cancel'),
});
