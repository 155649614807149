import ApiService from './ApiService';

const ProxiesService = {
  getProxiesList({ orderPath, ...params }) {
    const updatedParams = { ...params };
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '')
    }
    console.log('orderPath', orderPath)
    return ApiService.get('/proxy', updatedParams)
  },
  editProxies(params) {
    return ApiService.put('/proxy', params)
  },
  groupEditProxies(params) {
    return ApiService.put('/proxy/group', params)
  },
  createProxies(params) {
    return ApiService.post('/proxy', params)
  },
  fetchProxy(id) {
    return ApiService.get(`/proxy/${id}`);
  },
};

export default ProxiesService
