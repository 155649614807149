import React, { useCallback, useEffect, useState } from 'react';
import b_ from 'b_';
import './styles.scss';
import {Table} from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { balancesListFetch } from '../../Reducers/accounts';
import PrintPrice from '../../Components/Prints/PrintPrice';
import useTable from '../../Utils/useTable';

const b = b_.lock('AccountGroupBalances');

function AccountGroupBalances() {
  // const { payload, isLoading } = useSelector(state => state.accounts.balances, shallowEqual);

  const {
    list,
    isLoading,
  } = useTable(state => state.accounts.balances, balancesListFetch);

  const rowClassName = (record) => {
    if (record.name === 'TOTAL') {
      return 'highlighted-row';
    }
    return '';
  };

  const GetColumns = useCallback(() => {
    return [
        { 
          dataIndex: "name",
          title: "Group",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "allBal",
          title: "All",
          align: "center",
          render: (bal, rec) => (
                          <div className={b('col')}>
                         <PrintPrice value={rec.allBal} currency="€" />
                          &nbsp;<small className={b('count')}>({rec.allAccs})</small>
                      </div>
                    )},
       { 
          title: "Redeem",
          align: "center",
          dataIndex: "redeemBal",
          render: (bal, rec) => (
                      <div className={b('col')}>
                        <PrintPrice value={rec.redeemBal} currency="€" />
                        &nbsp;<small className={b('count')}>({rec.redeemAccs})</small>
                      </div>
                    )},
        { 
          title: "Enabled",
          align: "center",
          dataIndex: "enabledBal",
          render: (bal, rec) => (
                      <div className={b('col')}>
                        <PrintPrice value={rec.enabledBal} currency="€" />
                        &nbsp;<small className={b('count')}>({rec.enabledAccs})</small>
                      </div>
                    )},
          { 
            title: "Limited",
            align: "center",
            dataIndex: "limitedBal",
            render: (bal, rec) => (
                      <div className={b('col')}>
                        <PrintPrice value={rec.limitedBal} currency="€" />
                        &nbsp;<small className={b('count')}>({rec.limitedAccs})</small>
                      </div>
                    )},
          { 
            title: "Invalid",
            align: "center",
            dataIndex: "invalidBal",
            render: (bal, rec) => (
                      <div className={b('col')}>
                        <PrintPrice value={rec.invalidBal} currency="€" />
                        &nbsp;<small className={b('count')}>({rec.invalidAccs})</small>
                      </div>
                    )},
            { 
              title: "Disabled",
              align: "center",
              dataIndex: "disabledBal",
              render: (bal, rec) => (
                      <div className={b('col')}>
                        <PrintPrice value={rec.disabledBal} currency="€" />
                        &nbsp;<small className={b('count')}>({rec.disabledAccs})</small>
                      </div>
                    )},
              {
                align: "center",
                dataIndex: "codesRate",
                title: "Codes rate",
                width: 140,
                render: (value, record) => {
                  if (record.name === 'ALL') {
                    return '-';
                  }
                  return <p>{value ?  Math.round((value * 100)) + "%" : ("-")}</p>
                }
              },
              {
                align: "center",
                dataIndex: "allBal",
                title: "Balance to All %",
                width: 140,
                render: (value, record) => {
                  if (record.name === 'TOTAL') {
                    return '-';
                  }
              
                  const percentage = calculatePercentage(value);
                  return <p>{percentage.toFixed(2)}%</p>;
                }
              },
    ]
  }, [list])

   const calculatePercentage = useCallback(
    (value) => {
      let sum = 0
      list.forEach(item => {
        if (item.name === 'ALL') {
          sum = item.allBal
        }
      });
      if (sum === 0) {
        return 0;
      }
      return (value / sum) * 100;
    },
    [list]
  );

  const [columns, setColumns] = useState(GetColumns());
  const [updatedList, setUpdatedList] = useState([]);

  useEffect(() => {
    setColumns(GetColumns())
}, [list, GetColumns])

useEffect(() => {
    const updatedList = 
      list.map((item) => {
        if (item.name !== 'ALL' && item.isCurrency) {
          const updatedItem = { ...item };
          updatedItem.allBal = item.allBal;
          updatedItem.redeemBal = item.redeemBal;
          updatedItem.enabledBal = item.enabledBal;
          updatedItem.limitedBal = item.limitedBal;
          updatedItem.invalidBal = item.invalidBal;
          updatedItem.disabledBal = item.disabledBal;
          return updatedItem;
        }
        return item;
      })
    
    setColumns(GetColumns());
    setUpdatedList(updatedList)
      
    const updatedSum = calculateSum(updatedList);
    setUpdatedList((prev) => [...prev, updatedSum]);
}, [list]);

const calculateSum = useCallback((listToUpdate) => {
  const sum = {
    name: 'TOTAL',
    allBal: 0,
    allAccs: 0,
    redeemBal: 0,
    redeemAccs: 0,
    enabledBal: 0,
    enabledAccs: 0,
    limitedBal: 0,
    limitedAccs: 0,
    invalidBal: 0,
    invalidAccs: 0,
    disabledBal: 0,
    disabledAccs: 0,
    isCurrency: false,
    codesRate: 0,
  };

  listToUpdate.forEach(item => {
    if (item.name !== 'ALL') {
      sum.allBal += item.allBal;
      sum.allAccs += item.allAccs;
      sum.redeemBal += item.redeemBal;
      sum.redeemAccs += item.redeemAccs;
      sum.enabledBal += item.enabledBal;
      sum.enabledAccs += item.enabledAccs;
      sum.limitedBal += item.limitedBal;
      sum.limitedAccs += item.limitedAccs;
      sum.invalidBal += item.invalidBal;
      sum.invalidAccs += item.invalidAccs;
      sum.disabledBal += item.disabledBal;
      sum.disabledAccs += item.disabledAccs;
    }
  });

  return sum;
}, [list]);

const filteredList = updatedList.filter(item => item.name !== 'ALL');


  return (
  <Table 
        rowKey="name"
        bordered
        className={b('table')}
        dataSource={filteredList}
        pagination={false}
        columns={columns}
        loading={isLoading}
        size="small"
        scroll={{ x: "max-content" }}
        rowClassName={rowClassName}
  />
  );
}

export default AccountGroupBalances;
