import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import './styles.scss'
import { Typography, message, Input, Spin, Col, Row, Button } from 'antd'
import Title from 'antd/lib/typography/Title'
import useTable from '../../Utils/useTable'
import { accountsToCreateListFetch } from '../../Reducers/accountsToCreate'
import AccountsToCreateTable from './AccountsToCreateTable'
import GroupEnablingButtonGroup from './GroupEditing/GroupEnablingButtonGroup'
import { accountsListFetch, accountsListToCreateFetch, getAccountsPoolInfo, getCurrenciesList } from '../../Reducers/accounts'
import CurrencySelector from '../../Components/CurrencySelector'
import { B } from 'b_'
import { FilterOutlined } from '@ant-design/icons'

const { Search } = Input

function AccountsToCreate() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.accounts.toCreateList, accountsListToCreateFetch);

  const [selectedRow, setSelectedRow] = useState([]);
  // const [loading, setIsloading] = useState(true);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAccountsPoolInfo())
  }, [dispatch])

  useEffect(() => {
    dispatch(getCurrenciesList())
  }, [dispatch])

  const { payload } = useSelector(state => state.accounts.poolInfo, shallowEqual)
  

  const accounts = list.accounts && list.accounts
  // console.log('accounts', accounts)

  const poolData = payload && payload
  // console.log('payloadpool ', payload)

  const [curFilters, setCurFilters] = useState(filters);

  const handleChangeFilter = (value, field) => {
    const preparedValue = Array.isArray(value) ? value : value === '' ? null : [value];
    setCurFilters((prevFilters) => ({ ...prevFilters, [field]: preparedValue }));
  };

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Title level={2}>Pool Size</Title>
        {poolData?.map((curr, index) => {
          if (index !== poolData.length - 1) {
            return <span key={index}>{curr.currency}: {curr.poolSize}/{curr.poolMaxSize},{' '}</span>
          } else {
            return <span key={index}>{curr.currency}: {curr.poolSize}/{curr.poolMaxSize}</span>
          }
})}
       
      </div>
      <Typography.Title>Accounts to create</Typography.Title>
      <Row> 
          <Col>
          {/* <span className={'label'}>Group</span> */}
          <CurrencySelector value={curFilters.groups ? curFilters.groups[0] : null} onChange={(value) => handleChangeFilter(value, 'groups')} wd={'100px'} />
          
        </Col>
        <Button
          type="primary"
          onClick={() => onFilter(curFilters)}
          className={'item'}
          icon={<FilterOutlined />}
        >
          Filter
    </Button>
        
    </Row>
      <div className="buttonsGroup">
      
        <GroupEnablingButtonGroup selected={selectedRow} afterUpdate={fetch} />
        {
          !!selectedRow.length
          && <span style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginLeft: "15px", marginRight: "4px"}}><b>
            for {selectedRow.length} rows
          </b></span>
        }
      </div>
      
      <AccountsToCreateTable
        list={accounts}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
        setSelectedRow={setSelectedRow}
      />
    </>
  );
}

export default AccountsToCreate;
