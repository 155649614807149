import React, {useState, useCallback, useEffect, useMemo} from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Tooltip, Tag, message, InputNumber, Input, Select } from 'antd'
import b_ from 'b_'
import TableSettings from "./TableSettings"
import ImageViews from '../../Components/ImageViews'
import LinksViews from '../../Components/LinksViews'
import ExpandViews from '../../Components/ExpandViews'
import ActionButtons from './ActionButtons'
import { Table } from "ant-table-extensions";
// import { changeRatio, resetRatio, changeAction } from '../../Reducers/resell'
import useStorageState from '../../Utils/useStorageState'
import PrintProxiesStatus from '../../Components/Prints/PrintProxiesStatus'
import EnabledJobs from '../../Components/EnabledJobs'
import EditableField from '../../Components/EditableField'
import CurrencySelector from '../../Components/CurrencySelector'
import PhaseSelector from '../../Components/PhaseSelector'
import PrintAccountsStatus from '../../Components/Prints/PrintAccountStatus'
import { Link } from 'react-router-dom'
import PrintDate from '../../Components/Prints/PrintDate'
import TextArea from 'antd/es/input/TextArea'
import ExpandModal from '../../Components/ExpandModal'
import PrintDateSubscr from '../../Components/Prints/PrintDateSubscr'
import ViewSavedDataModal from '../../Components/ImageViews'
import { EyeOutlined, GlobalOutlined } from '@ant-design/icons'
import CarouselView from '../../Components/ViewsChildren/CarouselView'
import ListView from '../../Components/ViewsChildren/List'

const b = b_.lock('AccountsTable')

const getStringValue = (selectedValue) => {
  switch (selectedValue) {
    case 0:
      return 'No';
    case 1:
      return 'Pass';
    case 2:
      return 'EA';
    case 3:
      return 'Pass, EA';
    case 4:
      return 'Pass, Full';
    default:
      return '';
  }
};

function AccountsTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      onFilter,
                      filters,
                      selected,
                      setSelectedRow,
                      onSelectChange,
                      handleSave,
                      fieldsChange,
                      updateAccountStatus,
                      listData
                    }) {

  console.log('list', listData)

                      
  const GetColumns = useCallback(() => {

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 52,
        sorter: (a, b) => a - b,
        render:id => <Link to={`account/${id}`}>{id}</Link>,
      },
      {
        title: 'Login',
        dataIndex: 'login',
        key: 'login',
        fixed: 'left',
        width: 300,
        render:(login, rec) => (
          <EditableField handleSave={handleSave}
                         title="Update Login"
                         field={"login"}
                         rec={rec}
                         withTitle
                         titlePopover="Edit Login"
                         isLoading={loading}
                         iconClassName={b('edit')}
                         initialValue={login}
                         changeBlock={({onChange, ...props}) => (
                             <Input {...props}
                                    size="medium"
                                    onChange={e => onChange(e.target.value)}/>
                         )}>
              {login}
          </EditableField>
      ),
      },
      {
        title: 'Phase',
        dataIndex: 'phase',
        key: 'phase',
        width: 80,
        render:(value, rec) =>
              <EditableField handleSave={handleSave}
                             field={"phase"}
                             rec={rec}
                             title="Update Phase"
                             titlePopover="Edit phase"
                             iconClassName={b('edit')}
                             withTitle
                             isLoading={loading}
                             initialValue={value}
                             changeBlock={(props) => (
                                 <PhaseSelector
                                 {...props}
                                 data={[
                                          {
                                          value: "Create", 
                                          label: "Create"
                                          }, 
                                          {
                                            value: "Prepare", 
                                            label: "Prepare"
                                            },
                                            {
                                              value: "Redeem", 
                                              label: "Redeem"
                                              },
                                              {
                                                value: "Archive", 
                                                label: "Archive"
                                                },
                                                {
                                                  value: "PrepareArchive", 
                                                  label: "PrepareArchive"
                                                  },
                                                  {
                                                    value: "Purchase", 
                                                    label: "Purchase"
                                                    }
                                                  ]}
                                                   size="small"
                                                   className="w100"
                                 />
                             )}>
                  {value}
              </EditableField>
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 69,
        render:(status, rec) => (
          <EditableField
                    handleSave={handleSave}
                    field={"status"}
                             rec={rec}
                             title="Update Status"
                             titlePopover="Edit Status"
                             iconClassName={b('edit')}
                             withTitle
                             isLoading={loading}
                             initialValue={status}
                                   changeBlock={(props) => (
                                     <Select {...props} size="small">
                                       <Select.Option value="Disable">Disable</Select.Option>
                                       <Select.Option value="Enable">Enable</Select.Option>
                                     </Select>
                                   )}>
        <PrintAccountsStatus
          fieldsChange={fieldsChange}
          handleSave={updateAccountStatus}
              status={status}
              field={"name"}
              rec={rec}
              />
        </EditableField>
              )
      },
      {
        title: 'Enabled Jobs',
        dataIndex: 'enabledJobs',
        key: 'enabledJobs',
        align: 'center',
        width: 57,
        render:(enabledJobs, rec) => (
          <EnabledJobs
              fieldsChange={fieldsChange}
              enabledJobs={enabledJobs}
              field={"enabledJobs"}
              rec={rec}
              iconClassName={b('edit')}
              handleSave={handleSave}
          />)
      },
      {
        title: 'Group',
        dataIndex: 'currency',
        key: 'currency',
        width: 60,
        render:(value, rec) =>
              <EditableField handleSave={handleSave}
                             field={"currency"}
                             rec={rec}
                             title="Update Currency"
                             titlePopover="Edit Currency"
                             iconClassName={b('edit')}
                             withTitle
                             isLoading={loading}
                             initialValue={value}
                             changeBlock={(props) => (
                                 <CurrencySelector {...props}
                                                   size="small"
                                                   className="w100"
                                 />
                             )}>
                  {value}
              </EditableField>
      },
      {
        title: 'Balance (EUR)',
        dataIndex: 'balance',
        key: 'balance',
        width: 70,
        sorter: (a, b) => a - b,
        render: (balance, record) => (
          <Tooltip title={record?.details?.balance?.value}>
            <Link to={`/account/ord_hist/${record.id}`}>{balance?.toFixed(2)}</Link>
          </Tooltip>
        ),
      },
      {
        title: 'Limit (EUR)',
        dataIndex: 'dayLimitEur',
        key: 'dayLimitEur',
        width: 50,
        sorter: (a, b) => a - b,
        render:(value, rec) =>
        <EditableField handleSave={handleSave}
                       field={"dayLimitEur"}
                       rec={rec}
                       title="Update Limit"
                       titlePopover="Edit limit"
                       iconClassName={b('edit')}
                       withTitle
                       isLoading={loading}
                       initialValue={value}
                       changeBlock={({onChange, ...props}) => (
                        <Input {...props}
                               size="medium"
                               type="number"
                               onChange={e => onChange(e.target.value)}
                               />
                    )}>
            {value}
        </EditableField>
      },
      {
        title: 'Subscr type',
        dataIndex: 'details.subscrType',
        key: 'details.subscrType',
        width: 65,
        render:(value, rec) =>
              <EditableField handleSave={handleSave}
                             field={"subscrType"}
                             rec={rec}
                             title="Update Subscription"
                             titlePopover="Edit subscription"
                             iconClassName={b('edit')}
                             withTitle
                             isLoading={loading}
                             initialValue={value}
                             changeBlock={(props) => (
                                 <PhaseSelector
                                 data={[{value: 0, label: "No"}, {value: 1, label: "Pass"}, {value: 2, label: "EA"}, {value: 3, label: "Pass/EA"}, {value: 4, label: "Pass/Full"}]}
                                 {...props}
                                size="medium"
                                className="w100"
                                 />
                             )}>
                  {getStringValue(rec.details.subscrType)}
              </EditableField>
      },
      {
        title: 'Subscriptions',
        dataIndex: 'passUltNextCharge',
        key: 'subscriptions',
        align: 'center',
        width: 110,
        render: (_, rec) => {
        const now = Date.now()
        const passUltNextCharge = rec?.details?.passUltNextCharge
        return {
          props: {
            style: { background: now > passUltNextCharge ? "#fcf8e3" : "transparent", color: now > passUltNextCharge ?  "#c09853" : ""}
          },
          children:
              <>
              {rec?.details?.passUltNextCharge &&
              <>
                Ultimate: <PrintDateSubscr subscr date={rec?.details?.passUltNextCharge} />
              </>
              }
              {rec?.details?.eaAccessNextCharge &&
              <>
                <br />
                EA: <PrintDateSubscr subscr date={rec?.details?.eaAccessNextCharge} />
              </>
              }
              </>
        }
        }
      },
      {
        title: 'Game limit',
        dataIndex: 'gameLimit',
        key: 'gameLimit',
        align: 'center',
        sorter: (a, b) => a.allGamesCount - b.allGamesCount,
        width: 50,
        render: (value, rec) => {
          return (
            <div className={b('shots')}>
              <Tooltip title="Subscription"><Tag>{value}/{listData?.subscrAllGameMax}</Tag></Tooltip>
              <br/>
              <Tooltip title="All" placement='bottom'><Tag>{rec?.allGamesCount}/{rec?.allGamesMax}</Tag></Tooltip>
            </div>
          )
        
        }
      },
      {
        title: 'VM',
        dataIndex: 'vm',
        key: 'vm',
        width: 45,
        sorter: (a, b) => a - b,
        render:(value, rec) =>
        <EditableField handleSave={handleSave}
                       field={"vm"}
                       rec={rec}
                       title="Update VM"
                       titlePopover="Edit VM"
                       iconClassName={b('edit')}
                       withTitle
                       isLoading={loading}
                       initialValue={value}
                       changeBlock={({onChange, ...props}) => (
                        <Input {...props}
                               size="small"
                              //  type="number"
                               onChange={e => onChange(e.target.value)}
                               />
                    )}>
            {value}
        </EditableField>
      },
      {
        title: 'Proxy',
        dataIndex: 'proxy',
        key: 'proxy',
        width: 60,

      },
      {
        title: 'State changed at',
        dataIndex: 'statusTime',
        key: 'statusTime',
        width: 85,
        render: (statusTime) => <PrintDate withTime date={statusTime} />,
      },
      {
        dataIndex: "message",
        title: "Message",
        width: 150,
        render: (value, rec)  => (
        <span>
            {rec?.comment && (rec?.comment.includes("заблокирован на покупку") || rec?.comment.includes("Бан")) ? rec.comment : value}
        </span>),
    },
      {
        title: 'Details',
        dataIndex: 'screenshots',
        key: 'screenshots',
        width: 100,
        align: "center",
        render: (screenshots, rec) => {
          let internals = {
                vmGroupName: rec.vmGroupName,
                statusTime: rec.statusTime,
                details: rec.details,
                newOrderHistory: rec.newOrderHistory,
                lastOrderHistory: rec.lastOrderHistory
              }
          return (
          <div className={b('shots')} >
            <ViewSavedDataModal
                  disabled={screenshots?.length == 0}
                  data={screenshots}
                  dimensions={{ width: "90vw", height: "90vh" }}
                  buttonIcon={<EyeOutlined />}
                  children={
                    <CarouselView data={screenshots} />
                  }
                  />
                  <ViewSavedDataModal
                   disabled={rec?.pageSources.length == 0}
                   data={rec.pageSources} 
                   dimensions={{ width: "50vw", height: "50vh" }}
                   buttonIcon={<GlobalOutlined />}
                   children={
                    <ListView data={rec.pageSources} />
                   }
                   />
                   <ViewSavedDataModal
                   disabled={false}
                   data={internals} 
                   dimensions={{ width: "90vw", height: "90vh" }}
                   buttonIcon={"Internals"}
                   children={
                    
                      <ExpandViews title="Internals" content={internals} />
                    
                   }
                   />
                   <ViewSavedDataModal
                   disabled={false}
                   data={rec?.comment} 
                   dimensions={{ width: "90vw", height: "90vh" }}
                   buttonIcon={"Comments"}
                   children={
                    
                    <EditableField 
                      handleSave={handleSave}
                      rec={rec}
                      field={"comment"}
                      title="Update Comment"
                      titlePopover="Edit Comment"
                      isLoading={loading}
                      iconClassName={b('edit')}
                      withTitle
                      initialValue={rec?.comment}
                      changeBlock={({onChange, ...props}) => (
                        <TextArea {...props}
                                  size="medium"
                                  onChange={e => onChange(e.target.value)}/>)}>
                      {rec?.comment}
                    </EditableField>
                    
                   }
                   />
          </div>
          )
        }

      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 100,
        render: ( _, record) => <ActionButtons
        handleSave={handleSave}
        record={record}
        afterUpdate={afterUpdate}
        // value={item?.isActive?.toString() || ''}
      />
      },
    ]; 
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());
  
  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('accounts_table', {
      checkedList: Checkboxs,
      indeterminate: false,
      checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
      setAllCheckbox({
          checkedList,
          indeterminate: !!checkedList.length && checkedList.length < columns.length,
          checkAll: checkedList.length === columns.length,
      });
  }, [Checkboxs]);

  useEffect(() => {
    let allCheckList = []
    if(list?.length && !Checkboxs.length) {
        GetColumns().forEach((e) => {
            allCheckList.push( e[Object.keys(e)[1]])
            setCheckbox(allCheckList)
        })
    }
  }, [list, AllCheckbox, Checkboxs.length, GetColumns])

  useEffect(() => {
      if(AllCheckbox.checkAll) {
          setAllCheckbox({
              checkedList: GetColumns().map(e => e.title),
              indeterminate: false,
              checkAll: true,
          })
      }
  },[AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
      setAllCheckbox({
          checkedList: (e.target.checked && columns.map(e => e.title)) || [],
          indeterminate: false,
          checkAll: e.target.checked,
      });
  }, [Checkboxs]);

  useEffect(() => {
      setColumns(GetColumns())
  }, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const listWithoutToCreate = list && list.filter((acc) => acc.status !== 'ToCreate')
  // const loader = Object.keys(loadings).some(e => !!loadings[e]) || loading
  const loader = loading

  useEffect(() => {
    onSelectChange([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pagination])

  
  return <Table
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  const key = `${record.id}_${record.balance}`
                  setSelectedRow((prev) => prev.includes(key) ? prev.filter((it) => it !== key) : [...prev, key])
                },
              };
            }}
            scrollToFirstRowOnChange={true}
            rowKey={(record) => `${record.id}_${record.balance}`}
            className={b()}
            bordered
            dataSource={listWithoutToCreate}
            scroll={{ x: 'max-content' }}
            size="middle"
            onChange={(a, b, c) => tableOnChange(a, b, c)}
            onFilter={onFilter}
            pagination={pagination}
            loading={loader}
            columns={columnsFilter}
            rowClassName={() => b('lock')}
            title={() => <TableSettings
                onChangeCheckAll={onChangeCheckAll}
                allChecks={AllCheckbox}
                Checkboxs={AllCheckbox.checkedList}
                TableColumn={() =>GetColumns()}
                setCheckbox={onChangeCheckList}
            />}
            rowSelection={{
              preserveSelectedRowKeys: false,
              selectedRowKeys: selected,
              onChange: onSelectChange,
            }}
  />
}

export default AccountsTable;
