import b_ from 'b_';
import React, { useEffect, useState } from 'react';
import './styles.scss';
import TokenService from '../../Services/TokenService';
import AmazonOutlined from '@ant-design/icons/lib/icons/AmazonOutlined';
import { Card, Button } from 'antd';
import {isLocal} from "../../Utils/domainUtils";

const b = b_.lock('SingInForm');
const {
  REACT_APP_AUTH_REDIRECT_URL: REDIRECT_URL,
  REACT_APP_AUTH_REDIRECT_URL_LOCAL: REDIRECT_URL_LOCAL,
  REACT_APP_AUTH_CLIENT_ID: CLIENT_ID,
  REACT_APP_AUTH_CLIENT_ID_LOCAL: CLIENT_ID_LOCAL,
  REACT_APP_AUTH_URL: AUTH_URL,
  REACT_APP_AUTH_URL_LOCAL: AUTH_URL_LOCAL,
  REACT_APP_AUTH_RESPONSE_TYPE: RESPONSE_TYPE,
  REACT_APP_AUTH_SCOPE: SCOPE,
} = process.env;

function SingInForm() {
  const url = isLocal() ? `${AUTH_URL_LOCAL}/authorize?client_id=${CLIENT_ID_LOCAL}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&redirect_uri=${REDIRECT_URL_LOCAL}` :
      `${AUTH_URL}/authorize?client_id=${CLIENT_ID}&response_type=${RESPONSE_TYPE}&scope=${SCOPE}&redirect_uri=${REDIRECT_URL}`;

  const [sha, setSha] = useState(null);
  useEffect(() => {
    TokenService.generateAndSaveSha256().then((data) => {
      setSha(data);
    });
  }, []);

  return (
    <Card className={b('card')} title="Sign In">
      <a className={b('link')} href={sha && url + `&code_challenge_method=S256&code_challenge=${sha}`}>
        <Button type="primary" size="large" loading={sha === null}>
          <AmazonOutlined />
          Sign In with Amazon Cognito
        </Button>
      </a>
    </Card>
  );
}

export default SingInForm;
