import { downloadFile } from '../Utils/apiHelper';
import ApiService from './ApiService';

function convertToCSV(data) {
  if (!data || data.length === 0) {
    throw new Error('No accounts data provided for CSV conversion.');
  }

  const headers = Object.keys(data[0]);
  const rows = data.map(item => Object.values(item));

  const csvContent = [
    headers.join(','),
    ...rows.map(row => row.join(','))
  ].join('\n');

  return csvContent;
}

const AccountService = {

  getAccountsList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/accounts', updatedParams);
  },

  getAccountsToCreateList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/accounts_to_create', updatedParams);
  },

  downloadAccountsList() {
    return ApiService.get('/accounts/download')
      .then(response => {
        const data = response
        const fileName = 'accounts.csv';
        const type = 'csv';
        downloadFile({ data, fileName, type });
      })
      .catch(error => {
        // Handle error
        console.error('Error downloading accounts list:', error);
      });
  },

  fetchBalancesList() {
    return ApiService.get(`/group/balance`);
  },

  createAccount(data) {
    return ApiService.put('/account', data);
  },

  createAccounts(data) {
    return ApiService.upload('/accounts', data.get('file'), 'file');
  },

  fetchAux() {
    return ApiService.get('/accounts/aux');
  },

  fetchAccountsGroups() {
    return ApiService.get('/groups');
  },

  updateAccount(data) {
    const updatedData = Object.keys(data).reduce((newData, key) => {
      if (![null, undefined].includes(data[key])) {
        newData[key] = data[key];
      }
      return newData;
    }, {});

    return ApiService.patch('/accounts/update', updatedData);
  },

  updateAccountsOld(params) {
    return ApiService.post('/job/no_arg_action', params)
  },

  groupEditAccounts(params) {
    return ApiService.patch('/accounts/group', params)
  },

  fetchAccount(id) {
    return ApiService.get(`/account/${id}`);
  },

  getJobs(id) {
    return ApiService.get(`/account/${id}/jobs`);
  },

  getCurrencies() {
    return ApiService.get('/currency')
  },

  getPoolInfo() {
    return ApiService.get('/account_pool_info')
  },

  requestCanBuy(data) {
    return ApiService.post('/stat/games_can_buy', data)
  },

  fetchOrderHistory(accId) {
    return ApiService.get(`/account/${accId}/ord_hist`)
  },

  purchase(data) {
    return ApiService.post(`/purchase/by_account`, data)
  },

  removeUserDataDir(id) {
    return ApiService.get('/driver/remove_user_data_dir', { account_id: id });
  },

  unsubscribePassFull(id) {
    return ApiService.post('/job/no_arg_action', { accountId: id, name: "unsubscribe-full", requestId: `${Date.now().toString()}-unsubscr` });
  },

  setAccountProxy(id) {
    return ApiService.patch(`/account/set_proxy?account_id=${id}`);
  },

  accessAccountInfo(id) {
    return ApiService.get(`/account/access/${id}`);
  }

};

export default AccountService
