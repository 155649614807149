import { createAction, createReducer } from "@reduxjs/toolkit";
import { frc } from 'front-end-common';
import VirtualMachinesService from "../Services/VirtualMachinesService";

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const virtualMachinesListFetch = createAction('virtualMachines/list', (params) => ({
  payload: VirtualMachinesService.getVirtualMachinesList(params)
}))

export const virtualMachineCreate = createAction('virtualMachines/create', (params) => ({
  payload: VirtualMachinesService.createVirtualMachine(params)
}))

export const changeVirtualMachine = createAction('virtualMachines/edit', (params) => ({
  payload: VirtualMachinesService.editVirtualMachine(params)
}))

export const groupChangeVirtualMachines = createAction('virtualMachines/group-edit', (params) => ({
  payload: VirtualMachinesService.groupEditVirtualMachines(params)
}))

const initialState = {
  list: getDefaultState(),
  create: getDefaultState(),
  edit: getDefaultState(),
  groupEdit: getDefaultState(),
}

export default createReducer(initialState, {
  ...getDefaultHandler(virtualMachinesListFetch, 'list'),
  ...getDefaultHandler(virtualMachineCreate, 'create'),
  ...getDefaultHandler(changeVirtualMachine, 'edit'),
  ...getDefaultHandler(groupChangeVirtualMachines, 'groupEdit')
})
