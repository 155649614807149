import { CalculatorOutlined, ChromeOutlined, CloseCircleOutlined, CompassOutlined, GiftFilled, GiftOutlined, KeyOutlined, LockOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import UpdateButton from './UpdateButton'
import FetchJobButton from './FetchJobButton'
import CanBuyButton from './CanBuyButton';
import PurchaseButton from './PurchaseButton';
import RemoveChromeProfileButton from './RemoveChromeProfileButton';
import SetProxyButton from './SetProxyButton';
import UnsubscribePassButton from './UnsubscribePassButton';

function ActionButtons({ record, afterUpdate, handleSave}) {
  return <div className="actionButtonsGroup">
    <PurchaseButton record={record} afterUpdate={afterUpdate} icon={<ShoppingCartOutlined />} updatedValue="itemId" title="Purchase" />
    <UpdateButton record={record} afterUpdate={afterUpdate} title="Auth Key" updatedValue="authToken" icon={<KeyOutlined />} field="authToken" handleSave={handleSave} />
    <UpdateButton record={record} afterUpdate={afterUpdate} title="Password" updatedValue="password" icon={<LockOutlined />} field="password" handleSave={handleSave} />
    <FetchJobButton record={record} afterUpdate={afterUpdate} title="Calc Purched" jobName="calc-purched" icon={<CalculatorOutlined />} />
    <CanBuyButton afterUpdate={afterUpdate} title="Can Buy" icon={<GiftOutlined />} />
    <RemoveChromeProfileButton record={record} afterUpdate={afterUpdate} title="Remove Chrome Profile" icon={<ChromeOutlined />} />
    <SetProxyButton record={record} afterUpdate={afterUpdate} title="Set proxy" icon={<CompassOutlined />} />
    <UnsubscribePassButton record={record} afterUpdate={afterUpdate} title="Unsubscribe" icon={<CloseCircleOutlined />} />
  </div>
}

export default ActionButtons;
