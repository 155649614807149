import ApiService from './ApiService';

const AlgoStatisticsService = {
  getAlgoStatisticsList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/stat/algo_stat', updatedParams)
  },

  createAlgoStat(params) {
    return ApiService.post('/set_expected_purch', params)
  },

  groupEditAlgoStat(params) {
    return ApiService.put('/set_expected_purch/group', params)
  },
};

export default AlgoStatisticsService
