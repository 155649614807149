import React from 'react';
import { Col, Row } from 'antd';
import b_ from 'b_';
import { JSONTree } from 'react-json-tree';
import theme from '../../../../Utils/jsonTheme';
import './styles.scss';

const b = b_.lock('Expanded');

function Expanded({ className, record }) {
  const { details, lastOrderHistory, newOrderHistory, vmGroupName, statusTime } = record;

  return <div className={`${b()} ${className}`}>
    <Row>
    <Col span={12}>
      <div className={b('container')}>
          <b className="block mb">statusTime</b>
          <span>{statusTime}</span>
        </div>
        </Col>
   
    <Col span={12}>
      <div className={b('container')}>
          <b className="block mb">vmGroupName</b>
          <span>{vmGroupName}</span>
        </div>
        </Col>
    {/* </Row>
    <Row> */}
    
      <Col span={24}>
        <div className={b('container')}>
          <b className="block mb">Details</b>
          <JSONTree hideRoot theme={theme} data={details} shouldExpandNode={() => true} />
        </div>
      </Col>
      <Col span={24}>
        <div className={b('container')}>
          <b className="block mb">Last order history</b>
          <JSONTree hideRoot theme={theme} data={lastOrderHistory} shouldExpandNode={() => true} />
        </div>
      </Col>
      <Col span={24}>
        <div className={b('container')}>
          <b className="block mb">New order history</b>
          <JSONTree hideRoot theme={theme} data={newOrderHistory} shouldExpandNode={() => true} />
        </div>
      </Col>
      
    </Row>
  </div>;
}

export default Expanded;
