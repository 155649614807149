import { combineReducers } from 'redux'
import auth from './auth'
import accounts from './accounts'
import account from './account'
import purchaseJobs from './purchaseJobs'
import redeemJobs from './redeemJobs'
import otherJobs from './otherJobs'
import archiveAccounts from './archiveAccounts'
import virtualMachines from './virtualMachines'
import proxies from './proxies'
import purchaseStatistics from './purchaseStatistics'
import redeemOrder from './redeemOrder'
import algoStatistics from './algoStatistics'
import lostAndFound from './lostAndFound'
import accountsToCreate from './accountsToCreate'
import config from './config'

export default combineReducers({
  auth,
  account,
  accounts,
  accountsToCreate,
  purchaseJobs,
  redeemJobs,
  otherJobs,
  archiveAccounts,
  virtualMachines,
  proxies,
  purchaseStatistics,
  redeemOrder,
  algoStatistics,
  lostAndFound,
  config
})
