import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Input, message, InputNumber, Select} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import b_ from 'b_';
import { algoStatCreate } from '../../../Reducers/algoStatistics';
// import { changeRatios } from '../../../Reducers/resell';

const { Option } = Select

const b = b_.lock('NewButton');

function CreateAlgoStat({ className, afterUpdate, groups }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.accounts.create.isLoading, shallowEqual);

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const [proxy, setProxy] = useState("");


  

    const handleSuccess = useCallback((data) => {
      Object.keys(data).map(e => !data[e] ? data[e] = "" : null)
      dispatch(algoStatCreate(data))
      .then(() => {
        close();
        afterUpdate();
        message.success('Stat has been added!');
      })
      .catch((error) => {
        message.error(`Failed to create stat: ${error}`)
      });
    }, [close, afterUpdate, dispatch]);

  const error = useSelector(state => state.proxies.createProxies.error, shallowEqual);

  return (
    <>
      <Button
          type="primary"
          size="medium"
          className={className}
          icon={<PlusOutlined />}
          onClick={open}
      >
        Exp New
      </Button>
      <Drawer visible={isOpen} title="Create Account" width={600} closable placement="right" onClose={close}>
        {/* {error && <ErrorBlock message={error.message} />} */}
        {error && message.error('Fail')}
        <Form layout="vertical" className={b()} onFinish={handleSuccess}>
          <Form.Item
            label="Item ID"
            name="itemId"
          >
              <Input type="text"/>
          </Form.Item>
          <Form.Item
            rules={[
             { required: true, message: 'Please select currency' },
            ]}
            label="Currency"
            name="currency"
          >
            <Select>
              <Option value="USD">USD</Option>
              <Option value="EUR">EUR</Option>
              <Option value="BRL">BRL</Option>
              <Option value="COP">COP</Option>
              </Select>
          </Form.Item>
          <Form.Item
            label="Subscription"
            name="subscr"
          >
            <Select>
              <Option value="0">No</Option>
              <Option value="1">Pass</Option>
              <Option value="2">EA</Option>
              <Option value="3">Pass, EA</Option>
              <Option value="4">Pass, Full</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="On Sale"
            name="onSale"
          >
            <Select>
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          </Form.Item>
          <Form.Item
              label="Price"
              name="price"
          >
               <Input type="text"/>
          </Form.Item>
          <Form.Item
            label="Count"
            name="count"
          >
              <Input type="text"/>
          </Form.Item>
          <Form.Item>
            <Button
                type="primary"
                icon={isLoading && <LoadingOutlined />}
                htmlType="submit"
                >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default CreateAlgoStat
