import { createAction, createReducer } from "@reduxjs/toolkit";
import { frc } from 'front-end-common';
import ProxiesService from "../Services/ProxiesService";

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const proxiesListFetch = createAction('proxies/list', (params) => ({
  payload: ProxiesService.getProxiesList(params)
}))

export const changeProxies = createAction('proxies/edit', (params) => ({
  payload: ProxiesService.editProxies(params)
}))

export const groupChangeProxies = createAction('proxies/group-edit', (params) => ({
  payload: ProxiesService.groupEditProxies(params)
}))

export const createProxies = createAction('proxies/create', (params) => ({
  payload: ProxiesService.createProxies(params)
}))

const initialState = {
  list: getDefaultState(),
  createProxies: getDefaultState(),
  editProxies: getDefaultState(),
  groupEdit: getDefaultState(),
}

export default createReducer(initialState, {
  ...getDefaultHandler(proxiesListFetch, 'list'),
  ...getDefaultHandler(createProxies, 'createProxies'),
  ...getDefaultHandler(changeProxies, 'editProxies'),
  ...getDefaultHandler(groupChangeProxies, 'groupEdit')
})
