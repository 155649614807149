import React, { useCallback } from 'react';
import b_ from 'b_';
import { Layout as AntLayout } from 'antd';
import { Outlet } from 'react-router-dom';
import './styles.scss';
import { frc } from 'front-end-common';
import { useDispatch } from 'react-redux';
import { authSignOut } from '../../Reducers/auth';
import { history } from '../../Boots/createStore';
import { links } from '../../Boots/Routes';

const Content = AntLayout.Content;
const { Header } = frc.components

const b = b_.lock('Layout');

function MainLayout() {

  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(authSignOut());
  }, [dispatch]);

  return <AntLayout className={b()}>
    <AntLayout style={{ flexDirection: "column" }}>
    <Header className={b('header', { isCollapsed: true })}
              history={history} 
              links={links} 
              defaultKey={"xbox"} 
              handleLogout={handleLogout}
      />
      <Content className={b('content')}>
        <Outlet />
      </Content>
    </AntLayout>
  </AntLayout>;
}

export default MainLayout;
