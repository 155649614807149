import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

function CurrencySelector({ className, value, defaultValue, onChange, disabled, mode, wd }) {
  const { payload, isLoading } = useSelector(state => state.accounts.currencies, shallowEqual);

  return (
    <Select defaultValue={defaultValue}
            className={className}
            allowClear
            value={value}
            onChange={onChange}
            style={{ width: wd }}
            disabled={disabled}
            mode={mode}
    >
      {payload?.map(value => <Select.Option key={value} value={value}>{value}</Select.Option>)}
    </Select>
  )
}

CurrencySelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
};

export default CurrencySelector;
