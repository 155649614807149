import React, { useCallback, useState } from 'react';
import b_ from 'b_';
import { message, Button, Input, Affix } from 'antd';
import Title from 'antd/lib/typography/Title';
import useTable from '../../Utils/useTable';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import JobFilters from '../../Components/JobFilters';
import { cancelOtherJobs, otherJobsListFetch } from '../../Reducers/otherJobs';
import './styles.scss';
import { LoadingOutlined, CloseCircleOutlined } from "@ant-design/icons";
import CancelJobButton from '../../Components/CancelJobButton';
import JobsTableWrapper from '../../Components/JobsTableWrapper';

const b = b_.lock('OtherJob');

const { Search } = Input

function OtherJobs() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.otherJobs.list, otherJobsListFetch);


  return <div className={b()}>
    <Title className={`mb ${b('title')}`}>
      Other Jobs
    </Title>
    <JobFilters filters={filters} onFilter={onFilter} className="mb" withCodeFilter={false} />
    <JobsTableWrapper
        jobTableType='other_jobs_table'
        jobsList={list}
        onChange={onChange}
        pagination={pagination}
        isLoading={isLoading}
        fetch={fetch}
        onFilter={onFilter}
        filters={filters}
      />
  </div>;
}

export default OtherJobs;
