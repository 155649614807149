import React from 'react';
import { serverDateToDate, serverDateToDateWithTime } from '../../../Utils/dates';

function PrintDateSubscr({ className, date, withTime, eaSubscr, passSubscr, subscr }) {
  const prepareFunc = (
    withTime ? serverDateToDateWithTime : serverDateToDate
  );

  const now = Date.now()

  return (
  <span className={className}>
    {eaSubscr ? 
    `EA Access: ${<strike>{prepareFunc(date)}</strike>}`
    : 
    passSubscr ?
    `Ultimate: ${<strike>{prepareFunc(date)}</strike>}`
    :
    subscr && now > date ? 
    <strike>{prepareFunc(date)}</strike> 
    :
    date ?
    `${prepareFunc(date)}`
    :
    ''
    }
  </span>
  );
}

export default PrintDateSubscr;
