import React from 'react';
import PropTypes from 'prop-types';
import {Popover, Tag} from 'antd';
import "./styles.scss"

const STATE_TO_COLOR = {
  Found: 'green',
  Lost: 'red',
};

function PrintLostAndFound({ className, status, handleSave, rec, field, fieldsChange}) {
  const statuses = ["Lost", "Found"]
  const handleSaveUp = (value) => {
      const params = {}
      console.log(rec)
      fieldsChange.forEach(el => params[el] = rec[el] || "")
      params[field] = value
      handleSave(params);
  }

  return <Popover content={`Change Status on the ${statuses.filter(e => e !== status)[0]}`}>
      <Tag style={{ cursor: "pointer"}}
          onClick={() => handleSaveUp(...statuses.filter(e => e !== status))}
          className={[className, status]} color={STATE_TO_COLOR[status]}>{status}</Tag>
  </Popover>
}

PrintLostAndFound.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(STATE_TO_COLOR)).isRequired,
  rec: PropTypes.object,
  handleSave: PropTypes.func,
};

PrintLostAndFound.defaultProps = {
  className: '',
  rec: {},
};

export default PrintLostAndFound;
