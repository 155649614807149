import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { removeUserDataDir } from '../../../Reducers/accounts';
import { Button, Tooltip, message } from 'antd';
import b_ from 'b_';

const RemoveChromeProfileButton = ({ record, afterUpdate, title, icon }) => {
  const b = b_.lock('CheckPerksButton');

  const dispatch = useDispatch();

  const { id } = record;

  const removeUserData = useCallback(() => {
    dispatch(removeUserDataDir(id)).then(() => {
        message.success('Data user Chrome remove!');
        afterUpdate();
    }).catch(error => {
        console.log(error);
        message.error('Can\'t remove data Chrome user');
    })
}, [dispatch, afterUpdate, id]);
  
  return (
    <>
      <Tooltip placement="topLeft" title={title}>
        <Button
          icon={icon}
          onClick={removeUserData}
        />
      </Tooltip>
    </>
  );
}

export default RemoveChromeProfileButton
