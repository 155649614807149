import React, {useState, useCallback, useEffect, useMemo} from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Tooltip, Tag, message, Table, InputNumber } from 'antd'
import b_ from 'b_'
import ImageViews from '../../Components/ImageViews'
import LinksViews from '../../Components/LinksViews'
import ExpandViews from '../../Components/ExpandViews'
import useStorageState from '../../Utils/useStorageState'
import PrintLostAndFound from '../../Components/Prints/PrintLostAndFound'
import TableSettings from '../../Utils/TableSettings'
import PrintDate from '../../Components/Prints/PrintDate'
import { Link } from 'react-router-dom'

const b = b_.lock('LostAndFoundTable')

function LostAndFoundTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      onFilter,
                      filters,
                      selected,
                      onSelectChange,
                      handleSave,
                      fieldsChange
                    }) {
  const GetColumns = useCallback(() => {

    return [
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 60,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Item id',
        dataIndex: 'itemId',
        key: 'itemId',
        width: 60,
        sorter: (a, b) => a.itemId.localeCompare(b.itemId),
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        width: 60,
        sorter: (a, b) => a.price - b.price,
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        width: 90,
        sorter: (a, b) => a.currency.localeCompare(b.currency),
        filters: [
          {
            text: 'USD',
            value: 'USD',
          },
          {
            text: 'EUR',
            value: 'EUR',
          },
          {
            text: 'ARS',
            value: 'ARS',
          },
        ],
        filteredValue: filters['currency'] || null,
      },
      {
        title: 'Rate',
        dataIndex: 'rate',
        key: 'rate',
        width: 100,
        sorter: (a, b) => a.rate - b.rate,
      },
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: 300,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 100,
        sorter: (a, b) => a.status.localeCompare(b.status),
        render:(status, rec) => (<PrintLostAndFound
              fieldsChange={fieldsChange}
              handleSave={handleSave}
              status={status}
              field={"status"}
              rec={rec}/>)
      },
      {
        title: 'Creation time',
        dataIndex: 'creationTime',
        key: 'creationTime',
        width: 150,
        sorter: (a, b) => a - b,
        render: (creationTime) => <PrintDate withTime date={creationTime} />,
      },
      {
        title: 'Account',
        dataIndex: 'accId',
        key: 'accId',
        align: 'center',
        width: 20,
        sorter: (a, b) => a - b,
        render: accId => <Link to={`/account/${accId}`}>{accId}</Link>,
      },
      {
        title: 'Order num',
        dataIndex: 'orderNum',
        key: 'orderNum',
        width: 100,
        sorter: (a, b) => a - b,
      },
    ]; 
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('lost_and_found_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
});

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < columns.length,
        checkAll: checkedList.length === columns.length,
    });
}, [Checkboxs]);

useEffect(() => {
  let allCheckList = []
  if (list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
          allCheckList.push(e[Object.keys(e)[1]])
          setCheckbox(allCheckList)
      })
  }
}, [list, AllCheckbox, Checkboxs.length, GetColumns])

useEffect(() => {
  if (AllCheckbox.checkAll) {
      setAllCheckbox({
          checkedList: GetColumns().map(e => e.title),
          indeterminate: false,
          checkAll: true,
      })
  }
}, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
        checkedList: (e.target.checked && columns.map(e => e.title)) || [],
        indeterminate: false,
        checkAll: e.target.checked,
    });
}, [Checkboxs]);

useEffect(() => {
  setColumns(GetColumns())
}, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const loader = loading

  
  return <Table
            rowKey={(record) => `${record.id}`}
            className={b()}
            bordered
            dataSource={list}
            scroll={{ x: 'max-content' }}
            size="middle"
            onChange={(a, b, c) => tableOnChange(a, b, c)}
            onFilter={onFilter}
            pagination={pagination}
            loading={loader}
            columns={columnsFilter}
            title={() => <TableSettings
              onChangeCheckAll={onChangeCheckAll}
              allChecks={AllCheckbox}
              Checkboxs={AllCheckbox.checkedList}
              TableColumn={() => GetColumns()}
              setCheckbox={onChangeCheckList}
          />}
            rowClassName={() => b('lock')}
            rowSelection={{
              selectedRowKeys: selected,
              onChange: onSelectChange,
            }}
  />
}

export default LostAndFoundTable;
