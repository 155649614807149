import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import {Drawer, Form, Button, Input, message, InputNumber, Popconfirm} from 'antd';
import { EditFilled } from '@ant-design/icons';
import b_ from 'b_';
import splitArray from '../../../Utils/splitArray';
import { groupChangeProxies } from '../../../Reducers/proxies';
import { groupChangeAccounts } from '../../../Reducers/accounts';
// import { changeRatios } from '../../../Reducers/resell';

const GroupEnablingButtonGroup = ({
                     afterUpdate,
                     selected,
                     handleSave
                   }) => {

  const b = b_.lock('DisableButton');

  const dispatch = useDispatch();

  const reducedIds = selected.reduce((acc, row) => {
    const splitRow = row.split('_');
    if (splitRow.length > 0) {
      const number = parseInt(splitRow[0]);
      acc.push(number);
    }
    return acc;
  }, []);

  console.log('REDUCED', reducedIds)

  const handleSaveUp = (value) => {
    if(reducedIds) {
      const params = {
          accountIds: reducedIds
      }
      
      handleSave({...params, ...value});
      return
  }
}

  const handleSuccess = useCallback((data) => {
    console.log('data', data)
    console.log('selected', selected)

    if (selected.length) {
      const newArr = selected.map((el) => el.split(','))
      const preparedArr = splitArray(newArr)
      console.log('preparedArr', preparedArr)

      const handleData = preparedArr.map((item) => {
        return {...item, ...data}
      })
      console.log('handleData', handleData)

      dispatch(groupChangeAccounts(handleData))
      .then(() => {
        message.success('Accounts has been updated!');
        afterUpdate();
      }).catch((error) => {
        // console.log(error);
        message.error('Can\'t update accounts.');
      });
    }
  }, [dispatch, afterUpdate, selected]);
  
  return (
    <div style={{marginRight: '16px'}}>
      <Popconfirm
          title={`Are you sure to enable ${selected.length} ${selected.length === 1 ? 'product' : 'products'}?`}
          onConfirm={() => handleSaveUp({'status': 'Enable'})}
          okText="Yes"
          cancelText="No"
          placement="bottom"
        >
      <Button
        data-type="enable"
        type="primary"
        disabled={!selected.length}
        style={{marginRight: '16px'}}
        className="enabled"
      >
        Enable
      </Button>
      </Popconfirm>
    <Popconfirm
          title={`Are you sure to disable ${selected.length} ${selected.length === 1 ? 'product' : 'products'}?`}
          onConfirm={() => handleSaveUp({'status': 'Disable'})}
          okText="Yes"
          cancelText="No"
          placement="bottom"
        >
      <Button
        data-type="disable"
        type="primary"
        disabled={!selected.length}
        className="invalid"
      >
        Disable
      </Button>
      </Popconfirm>
    </div>
  );
};

export default React.memo(GroupEnablingButtonGroup);
