import React, {useState, useCallback, useEffect, useMemo} from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Tooltip, Tag, message, Table, InputNumber } from 'antd'
import b_ from 'b_'
import useStorageState from '../../Utils/useStorageState'
import PrintProxiesStatus from '../../Components/Prints/PrintProxiesStatus'
import TableSettings from '../../Utils/TableSettings'

const b = b_.lock('VirtualMachinesTable')

function VirtualMachinesTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      onFilter,
                      filters,
                      selected,
                      onSelectChange,
                      handleSave,
                      fieldsChange,
                      setSelectedRow
                    }) {

  
  const GetColumns = useCallback(() => {

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 52,
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: 'Group',
        dataIndex: 'groupName',
        key: 'groupName',
        width: 70,
        sorter: (a, b) => a.groupName.localeCompare(b.groupName),
      },
      {
        title: 'Driver',
        dataIndex: 'name',
        key: 'name',
        width: 70,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Host',
        dataIndex: 'host',
        key: 'host',
        width: 120,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Port',
        dataIndex: 'driverPort',
        key: 'driverPort',
        width: 80,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Type',
        dataIndex: 'vmType',
        key: 'vmType',
        width: 80,
        // sorter: (a, b) => a - b,
      },
      {
        title: 'Uses',
        dataIndex: 'uses',
        key: 'uses',
        width: 80,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: "center",
        width: 80,
        sorter: (a, b) => a.status.localeCompare(b.status),
        render:(status, rec) => (<PrintProxiesStatus
              fieldsChange={fieldsChange}
              handleSave={handleSave}
              status={status}
              field={"status"}
              rec={rec}/>)
      },
      {
        title: 'Engine ID',
        dataIndex: 'engineId',
        key: 'engineId',
        width: 80,
        // sorter: (a, b) => a - b,
      },
    ]; 
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('vm_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
});

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < columns.length,
        checkAll: checkedList.length === columns.length,
    });
}, [Checkboxs]);

useEffect(() => {
  let allCheckList = []
  if (list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
          allCheckList.push(e[Object.keys(e)[1]])
          setCheckbox(allCheckList)
      })
  }
}, [list, AllCheckbox, Checkboxs.length, GetColumns])

useEffect(() => {
  if (AllCheckbox.checkAll) {
      setAllCheckbox({
          checkedList: GetColumns().map(e => e.title),
          indeterminate: false,
          checkAll: true,
      })
  }
}, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
        checkedList: (e.target.checked && columns.map(e => e.title)) || [],
        indeterminate: false,
        checkAll: e.target.checked,
    });
}, [Checkboxs]);

  useEffect(() => {
      setColumns(GetColumns())
  }, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const loader = loading

  
  return <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    const key = `${record.id}`
                    setSelectedRow((prev) => prev.includes(key) ? prev.filter((it) => it !== key) : [...prev, key])
                  },
                };
              }}
              scrollToFirstRowOnChange={true}
            rowKey={(record) => `${record.id}`}
            className={b()}
            bordered
            dataSource={list}
            scroll={{ x: 'max-content' }}
            size="middle"
            onChange={(a, b, c) => tableOnChange(a, b, c)}
            onFilter={onFilter}
            pagination={pagination}
            loading={loader}
            columns={columnsFilter}
            title={() => <TableSettings
              onChangeCheckAll={onChangeCheckAll}
              allChecks={AllCheckbox}
              Checkboxs={AllCheckbox.checkedList}
              TableColumn={() => GetColumns()}
              setCheckbox={onChangeCheckList}
          />}
            rowClassName={() => b('lock')}
            rowSelection={{
              selectedRowKeys: selected,
              onChange: onSelectChange,
            }}
  />
}

export default VirtualMachinesTable;
