import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

function PhaseSelector({ data, className, value, defaultValue, onChange, disabled }) {
  // const { payload, isLoading } = useSelector(state => state.accounts.aux, shallowEqual);

  return (
    <Select defaultValue={defaultValue}
            className={className}
            allowClear
            value={value}
            onChange={onChange}
            style={{ width: '100px' }}
            disabled={disabled}
    >
      {data.map(value => <Select.Option key={value.value} value={value.value}>{value.label}</Select.Option>)}
    </Select>
  )
}

PhaseSelector.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.oneOf([PropTypes.bool, PropTypes.any]),
};

export default PhaseSelector;
