import Routes from '../Routes';
import './styles.css';

function App() {
  return (
  <div className="App">
    <Routes />
  </div>
  );
}

export default App;
