import ApiService from './ApiService';

const PurchaseStatisticsService = {
  getPurchaseStatisticsList({ orderPath, ...params }) {
    const updatedParams = { ...params };
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '')
    }
    return ApiService.get('/stat/purch_7_days', updatedParams)
  }
};

export default PurchaseStatisticsService
