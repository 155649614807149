import { Typography, Modal, Button } from 'antd'
import { JSONTree } from 'react-json-tree'
import theme from '../../Utils/jsonTheme'
import {frc} from 'front-end-common';
import './styles.scss';

const { Paragraph } = Typography
const useBoolean = frc.hooks.useBoolean;


function ExpandViews({ title, content, copyable = true }) {
  const { setTrue: open, value: isOpen, setFalse: close } = useBoolean(false);

  return <div>
      {
        Array.isArray(content)
          ? content.map((item) => <Paragraph copyable={copyable} key={item}><JSONTree invertTheme={false} hideRoot theme={theme} data={item} /></Paragraph>)
          : <Paragraph copyable={copyable}><JSONTree invertTheme={false} hideRoot theme={theme} data={content} /></Paragraph>
      }
  </div>;
}

export default ExpandViews;


