import { Button, Checkbox, Drawer, Form, Input, Select, Tooltip, message } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import useBoolean from '../../../Utils/useBoolean';
import b_ from 'b_';
import { purchase } from '../../../Reducers/accounts';

const b = b_.lock('PurchaseButton');

const { Option } = Select

const PurchaseButton = ({ record, afterUpdate, title, icon, updatedValue }) => {

  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const handleSuccess = useCallback((data) => {
    console.log(data)
    dispatch(purchase({
    "accountId": record.id, 
    "requestId": Date.now().toString(),
    "accountGroupName": record.currency,
    "itemId": form.getFieldValue("itemId"),
    "price": form.getFieldValue("price"),
    "subscr": form.getFieldValue("subscr"),
    "onSale": +form.getFieldValue("onSale"),
    "notificationUrl": null,
  }
    ))
    .then(() => {
      message.success('Successfuly started purchase job')
      afterUpdate()
    })
    .catch((error) => {
      message.error(`Failed to purchase: ${error}`)
    })
  }, [dispatch, afterUpdate, record]);

  return (
    <>
    <>
      <Drawer visible={isOpen} title={`${title} for ${record.login}`} width={500} closable placement="right" onClose={close}>
        <Form 
        layout="vertical" 
        className={b()} 
        form={form}
        onFinish={handleSuccess}
        >
          <Form.Item
            label={`${title} item id`}
            name={updatedValue} 
            rules={[
              {
                required: true,
                message: `please enter product id`,
              },
          ]}>
            <Input placeholder='For example: plague-inc/9nblggh0dnm7'/>
          </Form.Item>
          <Form.Item
            label={`${title} item price`}
            name="price"
            rules={[
              {
                required: true,
                message: `please enter product price`,
              },
          ]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label={`${title} item subscription`}
            name="subscr"
            rules={[
              {
                required: true,
                message: `please select subscription type`,
              },
          ]}>
            <Select>
              <Option value={0}>Without</Option>
              <Option value={1}>With</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={`${title} item on sale`}
            name="onSale"
            valuePropName="checked"
            >
            <Checkbox />
          </Form.Item>
          <Form.Item>
            <Button
                  type="primary"
                  htmlType="submit"
              >
                  Submit
              </Button>
          </Form.Item>
        </Form>
      </Drawer>
      <Tooltip placement="topLeft" title={title}>
        <Button
          type="primary"
          // disabled={!selected.length}
          // icon={<KeyOutlined />}
          style={{background: '#16c32a', borderColor: '#16c32a'}}
          icon={icon}
          onClick={open}
        />
      </Tooltip>
    </>
    </>
  )
}

export default PurchaseButton
