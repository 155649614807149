import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { Typography, message, Input } from 'antd'
import Title from 'antd/lib/typography/Title'
import useTable from '../../Utils/useTable'
import { archiveAccountsListFetch } from '../../Reducers/archiveAccounts'
import ArchiveAccountsTable from './ArchiveAccountsTable'
import SetPhaseButton from './GroupEditing/SetPhaseButton'
import { updateAccount } from '../../Reducers/accounts'
import { dearchive } from '../../Reducers/account'

const { Search } = Input

function ArchiveAccounts() {
  const {
    list,
    pagination,
    onChange,
    onFilter,
    isLoading,
    fetch,
    filters,
    error,
    query,
    onSearch
  } = useTable(state => state.archiveAccounts.list, archiveAccountsListFetch);

  const [selectedRow, setSelectedRow] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRow(newSelectedRowKeys);
  };

  const fieldsChange = [
    "login",
    "phase",
    "authToken",
    "currency",
    "comment",
    "driver",
    "enabledJobs",
]

  const dispatch = useDispatch()

  const updateAccountState = useCallback((data) => {
    dispatch(updateAccount(data)).then(() => {
        message.success('Account has been updated!');
        fetch();
    }).catch(error => {
        console.log(error);
        message.error('Can\'t update Account.');
    });
}, [dispatch, fetch]);

// const dearchiveAccount = useCallback((data) => {
//   console.log('DEARCHIVE', data)
//   dispatch(dearchive(data)).then(() => {
//       message.success('Account has been dearchived!');
//       fetch();
//   }).catch(error => {
//       console.log(error);
//       message.error('Can\'t dearchive account.');
//   });
// }, [dispatch, fetch]);

  return (
    <>
      <Typography.Title>Archive Accounts</Typography.Title>

      <div className="buttonsGroup">
        <SetPhaseButton selected={selectedRow} afterUpdate={fetch} />
        {
          !!selectedRow.length
          && <span style={{ display: "flex", alignItems: "center", marginBottom: "10px", marginLeft: "15px", marginRight: "4px"}}><b>
            for {selectedRow.length} rows
          </b></span>
        }
      </div>
        <Search defaultValue={query} placeholder="Search for account" className="mb" size="large" onSearch={onSearch} />
      <ArchiveAccountsTable
        list={list.accounts}
        tableOnChange={(a, b, c) => onChange(a, b, c)}
        pagination={pagination}
        loading={isLoading}
        afterUpdate={fetch}
        onFilter={onFilter}
        filters={filters}
        selected={selectedRow}
        onSelectChange={onSelectChange}
        handleSave={updateAccountState}
        fieldsChange={fieldsChange}
        // dearchiveAccount={dearchiveAccount}
      />
    </>
  );
}

export default ArchiveAccounts;
