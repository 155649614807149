import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {Drawer, Form, Button, Tooltip, Input, message, InputNumber} from 'antd';
import b_ from 'b_';
import { canBuyRequest, updateAccounts } from '../../../Reducers/accounts';
// import { changeRatios } from '../../../Reducers/resell';

const CanBuyButton = ({
                     record,
                     afterUpdate,
                     jobName,
                     icon,
                     title
                   }) => {

  const b = b_.lock('CheckPerksButton');

  const dispatch = useDispatch();

  const handleSuccess = useCallback((data) => {
    console.log(data)
    dispatch(canBuyRequest([
      {
        "itemId": "plague-inc/9nblggh0dnm7",
        "currency": "USD",
        "price": 1,
        "subscr": 0,
        "onSale": false,
        "count": 1
      }
    ]))
    .then((res) => {
      console.log('res', res)
      message.success('Successfuly requested can buy')
      afterUpdate()
    })
    .catch((error) => {
      message.error(`Failed to fetch: ${error}`)
    })
  }, [dispatch, afterUpdate]);
  
  return (
    <>
      <Tooltip placement="topLeft" title={title}>
        <Button
          type="primary"
          icon={icon}
          onClick={handleSuccess}
        />
      </Tooltip>
    </>
  );
};

export default React.memo(CanBuyButton);
