import React, {useRef} from 'react';
import b_ from 'b_';
import {Button, Drawer, message, Upload} from 'antd';
import UploadOutlined from '@ant-design/icons/UploadOutlined';
import {useDispatch} from 'react-redux';
import './styles.scss';
// import frc from "front-end-common/front-end-common";
import {createAccounts} from "../../../Reducers/accounts";
import useBoolean from '../../../Utils/useBoolean';

const b = b_.lock('CreateAccounts');

// const useBoolean = frc.hooks.useBoolean;

function CreateAccounts({ className, afterSuccess }) {
  const { value: isOpen, setFalse: close, setTrue: open } = useBoolean(false);
  const { current: form } = useRef(new FormData());
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (!form.has('file')) {
      return message.error('Please fill the form!');
    }

    dispatch(createAccounts(form)).then(() => {
      message.success('Accounts have been uploaded!');
      afterSuccess();
      close();
    })
  };

  const beforeUpload = (file) => {
    form.delete('file');
    form.append('file', file);
    return false;
  };

  const onRemove = () => {
    form.delete('file');
  };

  return (<>
    <Button
        type="primary"
        onClick={open}
        icon={<UploadOutlined />}>
      Create Accounts
    </Button>
    <Drawer visible={isOpen} title="Create Accounts" width={600} closable placement="right" onClose={close}>
      <div className={b('line')}>
        <Upload accept=".csv"
                onRemove={onRemove}
                beforeUpload={beforeUpload}
        >
          <Button
              type="primary"
              icon={<UploadOutlined />}
          >Select File</Button>
        </Upload>
      </div>
      <div className={b('line')}>
        <Button
            type="primary"
            onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Drawer>
  </>);
}

export default CreateAccounts;
