import React from 'react';
import PropTypes, { number } from 'prop-types';

function PrintPrice({ className, value, currency }) {
  if (typeof value !== 'number' || Number.isNaN(value)) {
    return <span>0.00€</span>
  }

  return <span className={className}>
    {value.toFixed(2).replace('.00', '')}
    {currency && currency}
  </span>;
}

PrintPrice.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  currency: PropTypes.string,
};

PrintPrice.defaultProps = {
  className: '',
  currency: '',
};

export default PrintPrice;
