import { createAction, createReducer } from '@reduxjs/toolkit';
import { frc } from 'front-end-common';
import RedeemJobsService from '../Services/RedeemJobsService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const RedeemJobsListFetch = createAction('redeemJobs/list', (params) => ({
  payload: RedeemJobsService.getList(params),
}))

const initState = {
  list: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(RedeemJobsListFetch, 'list'),
});
