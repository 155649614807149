import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom';
import { accessInfo } from '../../Reducers/accounts';
import { message } from 'antd';

const AccountAccess = () => {

  const params = useParams();
  const [accountInfo, setAccountInfo] = useState()

  const accId = +params.id
  
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(accessInfo(accId))
    .then((res) => {
      setAccountInfo(res)
    })
    .catch((e) => {
      message.error(`Failed to retrieve: ${e}`)
    })
  }, [])

  return (
    <div>{JSON.stringify(accountInfo)}</div>
  )
}

export default AccountAccess
