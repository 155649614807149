import { Modal, Button } from 'antd';
import useBoolean from '../../Utils/useBoolean';
// import frc from 'front-end-common/front-end-common';

// const useBoolean = frc.hooks.useBoolean;

function ViewSavedDataModal({
  key,
  disabled,
  data,
  dimensions,
  buttonIcon,
  children
}) {
  const { setTrue: open, value: isOpen, setFalse: close } = useBoolean(false);

  const { width, height } = dimensions;

  return <div key={key}>
    <Button
      disabled={disabled}
      type="primary"
      onClick={open}
      icon={buttonIcon}
      style={{width: '100%'}}
    >
      {Array.isArray(data) && data?.length > 0 ? `(${(data?.length)})` : ""}
    </Button>
    <Modal 
    open={isOpen} 
    destroyOnClose 
    closable 
    onCancel={close} 
    footer={null}
    width={width} 
    height={height} 
    centered
    >
      {children}
    </Modal>
  </div>;
}

export default ViewSavedDataModal;
