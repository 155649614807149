import React, {useState, useCallback, useEffect, useMemo} from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { Tooltip, Tag, message, Table, InputNumber } from 'antd'
import b_ from 'b_'
import ImageViews from '../../Components/ImageViews'
import LinksViews from '../../Components/LinksViews'
import ExpandViews from '../../Components/ExpandViews'
import useStorageState from '../../Utils/useStorageState'
import TableSettings from '../../Utils/TableSettings'
import EnabledJobs from '../../Components/EnabledJobs'
import PrintDate from '../../Components/Prints/PrintDate'
import { getStringValue } from '../../Utils/subscriptionMask'
import PrintAccountsStatus from '../../Components/Prints/PrintAccountStatus'
import { Link } from 'react-router-dom'
import PrintDateSubscr from '../../Components/Prints/PrintDateSubscr'

const b = b_.lock('RedeemOrderTable')

function RedeemOrderTable({
                      list,
                      tableOnChange,
                      pagination,
                      loading,
                      afterUpdate,
                      onFilter,
                      filters,
                      selected,
                      onSelectChange,
                      fieldsChange,
                      handleSave,
                      listData
                    }) {
  const GetColumns = useCallback(() => {

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 52,
        sorter: (a, b) => a - b,
      },
      {
        title: 'Login',
        dataIndex: 'login',
        key: 'login',
        width: 70,
        sorter: (a, b) => a.state.localeCompare(b.state),
        render:(login, rec) => <Link to={`/account/${rec?.id}`}>{login}</Link>,
      },
      {
        title: 'Phase',
        dataIndex: 'phase',
        key: 'phase',
        width: 70,
        sorter: (a, b) => a.state.localeCompare(b.state),
      },
      {
        title: 'State',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        sorter: (a, b) => a - b,
        render:(status, rec) => (<PrintAccountsStatus
              status={status}
              field={"name"}
              rec={rec}/>)
      },
      {
        title: 'Enabled Jobs',
        dataIndex: 'redeem_purch',
        key: 'redeem_purch',
        width: 80,
        sorter: (a, b) => a - b,
        render:(_, record) => (
          <EnabledJobs
              fieldsChange={fieldsChange}
              enabledJobs={record.enabledJobs}
              field={"enabledJobs"}
              rec={record}
              iconClassName={b('edit')}
              handleSave={handleSave}
          />)
      },
      {
        title: 'Currency',
        dataIndex: 'currency',
        key: 'currency',
        width: 80,
        sorter: (a, b) => a.state.localeCompare(b.state),
      },
      {
        title: 'Balance (EUR)',
        dataIndex: 'balance',
        key: 'balance',
        width: 90,
        sorter: (a, b) => a - b,
        render: (_, record) => (
          <Tooltip placement="topLeft" title={record.balance}>
            {record.balance}
          </Tooltip>
        ),
      },
      {
        title: 'Limit (EUR)',
        dataIndex: 'dayLimitEur',
        key: 'dayLimitEur',
        width: 90,
        sorter: (a, b) => a - b

      },
      {
        title: 'Subscr type',
        dataIndex: 'details.subscrType',
        key: 'details.subscrType',
        width: 65,
        filters: [
          {
            text: 'No',
            value: 'No',
          },
          {
            text: 'Pass',
            value: 'Pass',
          },
          {
            text: 'EA',
            value: 'EA',
          },
          {
            text: 'Pass, EA',
            value: 'Pass, EA',
          },
          {
            text: 'Pass, Full',
            value: 'Pass, Full',
          },
        ],
        filteredValue: filters['subscr_type'] || null,
        render: (_, record) => {
          if (record.details.subscrType) {
          return <div> {getStringValue(record.details.subscrType)} </div>
        } else {
        return <div>{''}</div>
        }
      }
      },
      {
        title: 'Subscriptions',
        dataIndex: 'subscriptions',
        key: 'subscriptions',
        width: 230,
        render: (_, rec) => {
          const now = Date.now()
          const passUltNextCharge = rec?.details?.passUltNextCharge
          return {
            props: {
              style: { background: now > passUltNextCharge ? "#fcf8e3" : "", color: now > passUltNextCharge ?  "#c09853" : ""}
            },
            children:
                <>
                {rec?.details?.passUltNextCharge &&
                <>
                  Ultimate: <PrintDateSubscr subscr withTime date={rec?.details?.passUltNextCharge} />
                </>
                }
                {rec?.details?.eaAccessNextCharge &&
                <>
                  <br />
                  EA: <PrintDateSubscr subscr withTime date={rec?.details?.eaAccessNextCharge} />
                </>
                }
                </>
          }
          }
      
      },
      {
        title: 'Game limit',
        dataIndex: 'gameLimit',
        key: 'gameLimit',
        width: 60,
        render: (value) => <span>{value}/{listData?.subscrAllGameMax}</span>
      },
      {
        title: 'Groups',
        dataIndex: 'groups',
        key: 'groups',
        width: 95,
        filters: [
          {
            text: 'EUR',
            value: 'EUR',
          },
          {
            text: 'USD',
            value: 'USD',
          },
          {
            text: 'ARS',
            value: 'ARS',
          },
          {
            text: 'TRY',
            value: 'TRY',
          },
          {
            text: 'BRL',
            value: 'BRL',
          },
        ],
        filteredValue: filters['groups'] || null,
        render: (_, record) => (
          record.groups && record.groups.map((group, index) => (
            <React.Fragment key={index}>
              {group}
              {index !== record.groups.length - 1 && <span>, </span>}
            </React.Fragment>
          ))
        ),
      },
      {
        title: 'VM',
        dataIndex: 'vm',
        key: 'vm',
        width: 63,
        sorter: (a, b) => a - b,

      },
      {
        title: 'State changed at',
        dataIndex: 'updateTime',
        key: 'updateTime',
        width: 165,
        render: (_, record) => <PrintDate withTime date={record.updateTime} />,
      },
      {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
        width: 103,
        sorter: (a, b) => a.state.localeCompare(b.state),

      },
      {
        title: 'Details',
        dataIndex: 'message',
        key: 'message',
        width: 70,

      },
      {
        title: 'Internals',
        dataIndex: 'internals',
        key: 'internals',
        width: 200,
        render: internals => <ExpandViews title="Internals" content={internals} />
      },
    ]; 
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('redeem_order_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
});

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
        checkedList,
        indeterminate: !!checkedList.length && checkedList.length < columns.length,
        checkAll: checkedList.length === columns.length,
    });
}, [Checkboxs]);

useEffect(() => {
  let allCheckList = []
  if (list?.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
          allCheckList.push(e[Object.keys(e)[1]])
          setCheckbox(allCheckList)
      })
  }
}, [list, AllCheckbox, Checkboxs.length, GetColumns])

useEffect(() => {
  if (AllCheckbox.checkAll) {
      setAllCheckbox({
          checkedList: GetColumns().map(e => e.title),
          indeterminate: false,
          checkAll: true,
      })
  }
}, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
        checkedList: (e.target.checked && columns.map(e => e.title)) || [],
        indeterminate: false,
        checkAll: e.target.checked,
    });
}, [Checkboxs]);
  
useEffect(() => {
  setColumns(GetColumns())
}, [list, loading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  const listWithoutNoRedeem = list?.filter((listItem) => listItem.canRedeem === true)
  console.log(`list: ${JSON.stringify(listWithoutNoRedeem)}`)
  const loader = loading

  
  return <Table
            rowKey={(record) => `${record.id}_${record.balance_eur}`}
            className={b()}
            bordered
            dataSource={listWithoutNoRedeem}
            scroll={{ x: 'max-content' }}
            size="middle"
            onChange={(a, b, c) => tableOnChange(a, b, c)}
            onFilter={onFilter}
            pagination={pagination}
            loading={loader}
            columns={columnsFilter}
            title={() => <TableSettings
              onChangeCheckAll={onChangeCheckAll}
              allChecks={AllCheckbox}
              Checkboxs={AllCheckbox.checkedList}
              TableColumn={() => GetColumns()}
              setCheckbox={onChangeCheckList}
          />}
            rowClassName={(record, index) => record.canRedeem ? 'row' :  'row_grey'}
            rowSelection={{
              selectedRowKeys: selected,
              onChange: onSelectChange,
            }}
  />
}

export default RedeemOrderTable;
