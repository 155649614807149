import { createAction, createReducer } from "@reduxjs/toolkit";
import { frc } from 'front-end-common';
import ArchiveAccountsService from "../Services/ArchiveAccountsService";

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;


export const archiveAccountsListFetch = createAction('archiveAccounts/list', (params) => ({
  payload: ArchiveAccountsService.getArchiveAccountsList(params)
}))

export const setPhaseForArchiveAccounts = createAction('archiveAccounts/setPhase', (data) => ({
  payload: ArchiveAccountsService.setPhaseForAccounts(data)
}))

export const dearchive = createAction('account/dearchive', (data) => ({
  payload: ArchiveAccountsService.dearchiveAccount(data),
}));

const initialState = {
  list: getDefaultState(),
  setPhase: getDefaultState(),
  dearchive: getDefaultState()
}

export default createReducer(initialState, {
  ...getDefaultHandler(archiveAccountsListFetch, 'list'),
  ...getDefaultHandler(setPhaseForArchiveAccounts, 'setPhase'),
  ...getDefaultHandler(dearchive, 'dearchive'),
})
