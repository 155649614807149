import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { removeUserDataDir, unsubscribePassUser } from '../../../Reducers/accounts';
import { Button, Tooltip, message } from 'antd';
import b_ from 'b_';

const UnsubscribePassButton = ({ record, afterUpdate, title, icon }) => {
  const b = b_.lock('CheckPerksButton');

  const dispatch = useDispatch();

  const { id } = record;

  const unsubscribePass = useCallback(() => {
    dispatch(unsubscribePassUser(id)).then(() => {
        message.success('Start unsubscribing user');
        afterUpdate();
    }).catch(error => {
        console.log(error);
        message.error('Can\'t unsubscribe user');
    })
}, [dispatch, afterUpdate, id]);
  
  return (
    <>
      <Tooltip placement="topLeft" title={title}>
        <Button
          type="primary"
          style={{background: 'rgb(218 45 45)', borderColor: 'rgb(218 45 45)'}}
          icon={icon}
          onClick={unsubscribePass}
        />
      </Tooltip>
    </>
  );
}

export default UnsubscribePassButton
