import { CloseCircleOutlined } from '@ant-design/icons'
import { Button, message } from 'antd'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { cancelPurchaseJobs } from '../../Reducers/purchaseJobs';

const CancelJobButton = ({ selectedRow, isLoading, fetch, setSelectedRow }) => {

  const dispatch = useDispatch();

  const cancelJobs = useCallback(() => {
    console.log('selectedRow', selectedRow)
    dispatch(cancelPurchaseJobs(selectedRow)).then(() => {
      message.success('Jobs have been canceled!');
      setSelectedRow([]);
      fetch();
    });
  }, [dispatch, selectedRow, fetch, setSelectedRow]);

  return (
    <div>
      <Button
        onClick={cancelJobs}
        danger
        disabled={!selectedRow.length}
        icon={<CloseCircleOutlined />}
        type="primary"
        // variant="contained"
        loading={isLoading}
        >
      Cancel Jobs
        </Button>
    </div>
  )
}

export default CancelJobButton
