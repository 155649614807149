import ApiService from './ApiService';

const RedeemOrderService = {
  getRedeemOrderList({ filters, orderPath, ...params }) {
    const updatedParams = { ...params, curr: 'USD' };

    if (filters && Object.keys(filters).length) {
      for (let key in filters) {
        updatedParams[key] = filters[key].join(',');
      }
    }
    if (orderPath) {
      updatedParams.order = orderPath.replace('end', '');
    }
    return ApiService.get('/accounts/redeem_order', updatedParams)
  }
};

export default RedeemOrderService
